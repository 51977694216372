import { computed } from "vue"
// import { useCurrentProjectPipeline } from "../../../../store/use"
import { DATASET_SOURCE_TYPES, TABS_MAP } from "@/shared/constants/data-viewer-constants"
import { useDataViewerPreparePath } from "../use-data-viewer-prepare-path"

export const useDataViewerTable = (props) => {
  const { preparePath } = useDataViewerPreparePath()
  // const {
  //   selectedBrick,
  //   interimResults,
  // } = useCurrentProjectPipeline()

  // const interimBrickUpdatedAt = computed(() => {
  //   const interimBrick = interimResults.value[selectedBrick.value?.id]
  //   return interimBrick?.updated_at
  // })

  const isDataPreviewTab = computed(() => {
    return props.grid === TABS_MAP.DATA_PREVIEW
  })

  const prepareStatsParams = () => {
    const pathOptions = preparePath({
      ...props.dataItem,
      datasetType: DATASET_SOURCE_TYPES.STATS,
    })
    const params = {
      limit: null,
      limit_type: null,
      is_full_dataset: true,
      path: pathOptions.targetPath,
      dataset_type: pathOptions.datasetType,
      // updated_at: interimBrickUpdatedAt.value,
    }
    if (props.dataItem?.projectAssetId) {
      params.projectAssetId = props.dataItem.projectAssetId
    }
    return params
  }

  const prepareDataParams = () => {
    const params = {
      path: props.dataItem.targetPath,
      dataset_type: props.dataItem.datasetType,
      limit: props.dataItem.limit,
      limit_type: props.dataItem.limitType,
      is_full_dataset: props.dataItem.isFullDataset,
      // updated_at: interimBrickUpdatedAt.value,
    }
    if (props.dataItem.pipelineId) {
      params.pipelineId = props.dataItem.pipelineId
    }
    if (props.dataItem.groupId) {
      params.groupId = props.dataItem.groupId
    }
    if (props.dataItem.projectAssetId) {
      params.projectAssetId = props.dataItem.projectAssetId
    }
    return params
  }

  const gridDataRequestParams = computed(() => {
    if (!isDataPreviewTab.value) {
      return prepareStatsParams()
    }

    return prepareDataParams()
  })

  return {
    isDataPreviewTab,
    gridDataRequestParams,
  }
}
