<template>
  <OGrid
    :grid-key="gridKey"
    :headers="headers"
    :data-items="dataItems"
    :is-loading="isLoading"
    :total="total"
    :page="page"
    :is-disabled-row-hover="false"
    :is-header-uppercase="false"
    :is-cell-preview="isCellPreview"
    :is-cell-selectable="isCellSelectable"
    class="o-grid-preview-data"
    @[EVENT_PAGE_CHANGE]="(event) => $emit(EVENT_PAGE_CHANGE, event)"
    @[EVENT_SORT_CHANGE]="(event) => $emit(EVENT_SORT_CHANGE, event)"
    @[EVENT_CELL_COPY]="(event) => $emit(EVENT_CELL_COPY, event)"
    @[EVENT_CELL_SELECT]="(event) => $emit(EVENT_CELL_SELECT, event)"
    @[EVENT_CELL_PREVIEW]="(event) => $emit(EVENT_CELL_PREVIEW, event)"
  >
    <!-- :sort-by.sync="sortByValue"
    :sort-desc.sync="sortDescValue" -->
    <template
      v-if="dataItems.length"
      #filter
    >
      <OGridPreviewDataCellType
        v-for="(column, idx) in headers"
        :key="columnTypes[column.value].name + idx"
        :column="column"
        :data-types="columnTypes"
        :is-disabled-types="isDisabledTypes"
        @[EVENT_TYPE_CHANGE]="($event) => $emit(EVENT_TYPE_CHANGE, $event)"
      />
    </template>
  </OGrid>
</template>

<script>
import {
  computed,
  defineComponent,
} from 'vue'

import OGrid from '@/shared/components/o-grid'
import OGridPreviewDataCellType from './o-grid-preview-data-cell-type'

import {
  EVENT_CELL_COPY,
  EVENT_CELL_SELECT,
  EVENT_CELL_PREVIEW,
  EVENT_TYPE_CHANGE,
  EVENT_SORT_CHANGE,
  EVENT_PAGE_CHANGE,
} from '@/shared/constants/bus-events-constants'

export default defineComponent({
  name: 'OGridPreviewData',
  components: {
    OGrid,
    OGridPreviewDataCellType,
  },
  props: {
    gridKey: {
      type: String,
      default: () => 'grid',
    },
    headers: {
      type: Array,
      default: () => [],
    },
    columnTypes: {
      type: Object,
      default: () => ({}),
    },
    dataItems: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: () => false,
    },
    total: {
      type: Number,
      default: () => null,
    },
    page: {
      type: Number,
      default: () => 1,
    },
    isDisabledTypes: {
      type: Boolean,
      default: () => true,
    },
    /**
     * Possibility to preview cell text if it more than 1000 chars true/false
     */
    isCellSelectable: {
      type: Boolean,
      default: () => false,
    },
    sortBy: {
      type: String,
      default: () => null,
    },
    sortDesc: {
      type: Boolean,
      default: () => null,
    },
    /**
     * Possibility to preview cell text if it more than 1000 chars true/false
     */
    isCellPreview: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props, { emit }) {
    const sortByValue = computed({
      get: () => props.sortBy,
      set: (value) => emit('update:sortBy', value),
    })
    const sortDescValue = computed({
      get: () => props.sortDesc,
      set: (value) => emit('update:sortDesc', value),
    })

    return {
      sortByValue,
      sortDescValue,
      EVENT_CELL_COPY,
      EVENT_CELL_SELECT,
      EVENT_CELL_PREVIEW,
      EVENT_PAGE_CHANGE,
      EVENT_TYPE_CHANGE,
      EVENT_SORT_CHANGE,
    }
  },
})
</script>

<style lang="scss">
@import "o-grid-preview-data";
</style>
