<template>
  <v-tooltip
    :top="placement==='top'"
    :bottom="placement==='bottom'"
    :left="placement==='left'"
    :right="placement==='right'"
    :color="colorComputed"
    :nudge-bottom="nudgeBottom"
    :attach="attach"
    :max-width="maxWidth"
    :disabled="isDisabled"
    :open-delay="delayOnMouseOver"
    content-class="a-tooltip-content"
  >
    <template v-slot:activator="{ props }">
      <div
        v-bind="props"
        class="a-tooltip-slot"
      >
        <slot class="a-tooltip-slot" />
      </div>
    </template>
    <span>{{ content }}</span>
  </v-tooltip>
</template>

<script>
import {
  computed,
  defineComponent,
} from 'vue'

const TYPE_COLOR_VALUE = {
  SUCCESS: '#66BC6C',
  WARNING: '#E39736',
  ERROR: '#cc3d39',
}

const TYPE_COLOR_TITLE = {
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
}

export default defineComponent({
  name: 'ATooltip',
  /**
   * See https://vuetifyjs.com/en/api/v-tooltip/ for more info
   */
  props: {
    /**
     * Tooltip text content
     */
    content: {
      type: [String, Number],
      default() {
        return ''
      },
    },
    /**
     * Minimal length of content to make tooltip display itself
     */
    minLength: {
      type: Number,
      default() {
        return null
      },
    },
    /**
     * Can be: top, bottom, left, right
     */
    placement: {
      type: String,
      default() {
        return 'top'
      },
    },
    /**
     * Wait time before tooltip will be shown
     */
    delayOnMouseOver: {
      type: Number,
      default() {
        return 500
      },
    },
    /**
     * Tooltip max width
     */
    maxWidth: {
      type: [String, Number],
      default() {
        return 220
      },
    },
    /**
     * Applies specified color to the control - it can be the name of material color
     * (for example success or purple) or css color (#033 or rgba(255, 0, 0, 0.5)).
     *  You can find a list of built-in classes on the API colors page.
     */
    color: {
      type: String,
      default: '#222528',
    },
    /**
     * Tooltip vertical offset, px
     */
    nudgeBottom: {
      type: Number,
      default: 0,
    },
    /**
     * Specifies which DOM element that this component should detach to.
     * String can be any valid querySelector and Object can be any valid Node.
     * This will attach to the root v-app component by default.
     */
    attach: {
      type: Boolean,
      default: false,
    },
    /**
     * is tooltip disabled
     */
    isTooltipDisabled: {
      type: Boolean,
      default() {
        return false
      },
    },
    /**
     * Use special tooltip style. If you prop 'type', another prop 'color' will be ignored
     */
    type: {
      type: String,
      default() {
        return null
      },
      validator(value) {
        return [...Object.values(TYPE_COLOR_TITLE), null].includes(value)
      },
    },
  },
  setup(props) {
    const isDisabled = computed(() => {
      if (props.content && props.minLength) {
        return props.content.length <= props.minLength
      }
      return !props.content || props.isTooltipDisabled
    })

    const colorComputed = computed(() => {
      if (props.type === TYPE_COLOR_TITLE.SUCCESS) {
        return TYPE_COLOR_VALUE.SUCCESS
      }
      if (props.type === TYPE_COLOR_TITLE.WARNING) {
        return TYPE_COLOR_VALUE.WARNING
      }
      if (props.type === TYPE_COLOR_TITLE.ERROR) {
        return TYPE_COLOR_VALUE.ERROR
      }
      return props.color
    })

    return {
      isDisabled,
      colorComputed,
    }
  },
})
</script>

<style lang="scss">
@import "a-tooltip";
</style>
