<template>
  <div class="o-data-viewer-tables-data">
    <ODataViewerTable
      v-for="(dataItem, index) in checkedDataItems"
      :key="dataItem.key + 'data'"
      :grid="TABS_MAP.DATA_PREVIEW"
      :data-item="checkedDataItems[index]"
    />
  </div>
</template>

<script>
export default {
  name: 'ODataViewerTablesData',
}
</script>

<script setup>
import { defineProps } from 'vue'
import ODataViewerTable from '../o-data-viewer-table'

import { TABS_MAP } from '@/shared/constants/data-viewer-constants'

defineProps({
  checkedDataItems: {
    type: Array,
    default: () => [],
  },
})
</script>

<style lang="scss">
@import "o-data-viewer-tables-data";
</style>
