<template>
  <div
    class="o-data-viewer-table-tab"
    >
    <!-- v-bind="$attrs" -->
    <div class="data-viewer-table-tab__title">
      {{ title }}
    </div>
    <ABadge
      v-if="count"
      :text="count"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue'

import ABadge from '@/shared/components/a-badge'

export default defineComponent({
  name: 'ODataViewerTableTab',
  components: {
    ABadge,
  },
  props: {
    title: {
      type: String,
      default: () => '',
    },
    count: {
      type: Number,
      default: () => null,
    },
  },
})
</script>

<style lang="scss">
@import "o-data-viewer-table-tab";
</style>
