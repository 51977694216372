<template>
  <div class="a-badge">
    {{ text }}
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ABadge',
  props: {
    text: {
      type: [String, Number],
      default: () => '',
    },
  },
})
</script>

<style lang="scss">
@import "a-badge";
</style>
