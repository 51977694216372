<template>
  <div
    :class="classOptions"
    class="a-button-status"
    @click="handleClick"
  >
    <div
      v-if="icon"
      :class="iconClassOptions"
      class="button-status__icon"
    />
    <slot />
  </div>
</template>

<script>
import { computed } from 'vue'
import { taskStatuses } from '@/shared/constants/task-statuses-constants'
import { EVENT_CLICK } from '@/shared/constants/bus-events-constants'

const statuses = {
  DEFAULT: 'default',
  PENDING: taskStatuses.PENDING,
  SUCCESS: taskStatuses.SUCCESS,
  FAILURE: taskStatuses.FAILURE,
}

export default {
  name: 'AButtonStatus',
  props: {
    /**
     * Button status,
     */
    status: {
      type: String,
      default: taskStatuses.PENDING,
    },
    icon: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const classOptions = computed(() => {
      return {
        'button-status--default': props.status === statuses.DEFAULT,
        'button-status--pending': props.status === statuses.PENDING,
        'button-status--success': props.status === statuses.SUCCESS,
        'button-status--failure': props.status === statuses.FAILURE,
      }
    })

    const iconClassOptions = computed(() => {
      return {
        'button-status__icon--default': props.status === statuses.DEFAULT,
        'button-status__icon--pending': props.status === statuses.PENDING,
        'button-status__icon--success': props.status === statuses.SUCCESS,
        'button-status__icon--failure': props.status === statuses.FAILURE,
      }
    })

    function handleClick(e) {
      /**
       * Emitted { native event } when click
       */
      emit(EVENT_CLICK, e)
    }

    return {
      classOptions,
      iconClassOptions,
      handleClick,
    }
  },
}
</script>

<style lang="scss">
@import "a-button-status";
</style>
