<template>
  <div
    @mouseover="onHover"
    @mouseleave="onHoverLeave"
    class="a-icon-thumbs"
    :style="styleOptions"
    @click="$emit('click', direction)"
  >
    <svg
      v-if="isMouseOver || selected"
      width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
    >
      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.0721 3.66713C10.3426 3.05865 10.946 2.6665 11.6119 2.6665C13.299 2.6665 14.6667 4.03421 14.6667 5.72137V8.49984H17.4194C19.5645 8.49984 21.2049 10.4119 20.8787 12.532L19.9813 18.3654C19.7186 20.0728 18.2494 21.3332 16.5219 21.3332H5.33341C3.86066 21.3332 2.66675 20.1393 2.66675 18.6665V12.8332C2.66675 11.3604 3.86066 10.1665 5.33341 10.1665H7.18354L10.0721 3.66713ZM6.83341 12.1665V19.3332H5.33341C4.96522 19.3332 4.66675 19.0347 4.66675 18.6665V12.8332C4.66675 12.465 4.96522 12.1665 5.33341 12.1665H6.83341Z" fill="#667479"/>
    </svg>
    <svg
      v-else
      width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7.83341 20.3332V11.1665M3.66675 12.8332V18.6665C3.66675 19.587 4.41294 20.3332 5.33341 20.3332H16.522C17.7559 20.3332 18.8053 19.4329 18.9929 18.2133L19.8903 12.38C20.1233 10.8656 18.9516 9.49984 17.4194 9.49984H14.5001C14.0398 9.49984 13.6667 9.12674 13.6667 8.6665V5.72137C13.6667 4.5865 12.7468 3.6665 11.6119 3.6665C11.3412 3.6665 11.0959 3.82592 10.986 4.07327L8.05336 10.6716C7.91961 10.9726 7.62118 11.1665 7.29185 11.1665H5.33341C4.41294 11.1665 3.66675 11.9127 3.66675 12.8332Z" stroke="#667479" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </div>
</template>

<script setup>
import { defineEmits, defineProps, ref, computed } from 'vue';

const props = defineProps({
  direction: {
    type: String,
    default: 'up'
  },
  selected: {
    type: Boolean,
    default: false
  }
});
defineEmits(['click']);

const isMouseOver = ref(false)

const styleOptions = computed(() => {
  return {
    'transform': props.direction === 'down' ? 'rotate(180deg)' : '',
  }
})

const onHover = () => {
  isMouseOver.value = true
}
const onHoverLeave = () => {
  isMouseOver.value = false
}
</script>

<style lang="scss">
.a-icon-thumbs {
  display: flex;
  justify-content: start;
  align-items: center;
  width: 1.2rem;
  cursor: pointer;
}
</style>