<template>
  <AIcon
    :direction="iconDirection"
    is-original
    :scale="scale"
    name="icons/arrow-down"
  />
</template>

<script>
import { computed } from 'vue'

import AIcon from '@/shared/components/a-icon'

import { DIRECTION } from '@/shared/constants/placement-direction-constants'

export default {
  name: 'AIconArrow',
  components: {
    AIcon,
  },
  props: {
    active: {
      type: Boolean,
      default: () => false,
    },
    scale: {
      type: String,
      default: () => '1',
    },
  },
  setup(props) {
    const iconDirection = computed(() => props.active ? DIRECTION.DOWN : DIRECTION.UP)

    return {
      iconDirection,
    }
  },
}
</script>
