<template>
  <div
    class="a-column-type"
  >
    {{ columnTypeShort }}
  </div>
</template>

<script setup>
import { computed, defineProps } from 'vue'
import { SHORT_TYPES } from '@/shared/constants/data-types-constants'

const props = defineProps({
  columnType: {
    type: String,
    default: '',
  },
})

const columnTypeShort = computed(() => {
  const type = SHORT_TYPES[props.columnType.toUpperCase()] || ''
  return type
})

</script>

<style lang="scss">
@import "./a-column-type.scss";
</style>
