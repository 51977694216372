<template>
  <div
    :at-o-grid="atAttribute"
    class="o-grid"
  >
    <v-data-table-server
      :headers="headers"
      :items="dataItems"
      :dense="true"
      :page="page"
      :calculate-widths="true"
      :hide-default-footer="isHideFooter || currentItemsPerPage >= totalItems"
      :items-length="total"
      :footer-props="footerProps"
      :class="gridClass"
      :disable-pagination="isDisablePagination"
      :loading="isLoading"
      show-current-page
      :no-data-text="noRecordsMessage"
      :items-per-page-options="defaultPageSizes"
      @update:itemsPerPage="itemsPerPageHandler"
      @update:options="pageChangeHandler"
    >
      <!-- :options.sync="gridOptions"
      :sort-by.sync="sortByValue"
      :sort-desc.sync="sortDescValue" -->
      <template #headers="{ columns }">
        <tr>
          <template v-for="(column, idx) in columns" :key="column.value + idx">
            <AGridCellHeader
              :is-show-copy-button="isShowCopyButton"
              :title="column.title"
              :value="column.value"
              :sort-by="sortByValue"
              :sortable="column.sortable"
              :is-header-uppercase="isHeaderUppercase"
              :style="getColumnStyle(column)"
              @[EVENT_SORT_CHANGE]="handleSort"
              />
              <!-- :sort-desc.sync="sortDescValue" -->
          </template>
        </tr>
      </template>
      <template #item="{ item, index }">
        <tr>
          <td
            v-for="(column, idx) in headers"
            :key="column.value + idx"
            :style="column.style"
            class="grid__cell"
            :class="{ 'grid__cell--render': column.cellRender }"
            @dblclick="onCellPreview(column.title, index, String(item[column.value]))"
          >
            <CellRender
              v-if="column.cellRender"
              :value="column.value"
              :column="column"
              :data-item="item"
              @rowAction="$event => rowActionHandler(index, $event, item)"
            />
            <AGridCellText
              v-else
              :at-attribute="column.value"
              :is-cell-selectable="isCellSelectable"
              :is-cell-highlighted="isCellSelectable && item[column.value] == null"
              @copy="onCellCopy(column.title, index, String(item[column.value]))"
              @preview="onCellPreview(column.title, index, String(item[column.value]))"
              @[EVENT_CELL_SELECT]="selectCell($event, column.title, index, String(item[column.value]))"
            >
              <v-tooltip
                top
                :open-delay="500"
                :max-width="800"
              >
                <template #activator="{ props }">
                  <span
                    v-bind="props"
                    class="grid-cell-text"
                  >
                    {{ String(item[column.value]) }}
                  </span>
                </template>
                <span class="grid__cell-tooltip">
                  {{ String(item[column.value]) }}
                </span>
              </v-tooltip>
            </AGridCellText>
          </td>
        </tr>
      </template>
      <template
        v-if="isShowCurrentPage"
        #[`footer.page-text`]="{ pageStart, pageStop, itemsLength }"
      >
        {{ pageStart }} - {{ pageStop }} of {{ itemsLength }}
        <div class="grid__current-page-wrapper">
          <span class="grid__current-page-title">
            Page
          </span>
          <v-text-field
            :value="currentPage"
            :dense="true"
            :hide-details="true"
            type="number"
            class="grid__current-page"
            @blur="changePage"
            @keyup.enter="changePage"
          />
        </div>
      </template>
      <template
        v-if="totalItems"
        #[`footer.prepend`]
      >
        <div class="footer-prepend">
          <div
            v-if="isShowFooterColsRows"
            class="footer-prepend__count"
          >
            {{ headers.length }} cols, {{ totalItems }} rows
          </div>
          <div v-if="footerPrependText" class="footer-prepend__additional-info">
            {{ footerPrependText }}
          </div>
        </div>
      </template>
    </v-data-table-server>
  </div>
</template>
<script>
import {
  ref,
  computed,
  onMounted,
  defineComponent,
} from 'vue'

import AGridCellText from './a-grid-cell-text'
import AGridCellHeader from './a-grid-cell-header'
import CellRender from './m-grid-cell-wrapper/cell-render'

import useGridStorage from './use/use-grid-storage'
import { useGridResize } from './use-grid-resize'

import {
  CELL_PREVIEW_MIN_CHARS,
} from './o-grid-constants'
import {
  EVENT_ROW_ACTION,
  EVENT_PAGE_CHANGE,
  EVENT_SORT_CHANGE,
  EVENT_CELL_COPY,
  EVENT_CELL_SELECT,
  EVENT_CELL_PREVIEW,
} from '@/shared/constants/bus-events-constants'

export default defineComponent({
  name: 'OGrid',
  components: {
    CellRender,
    AGridCellText,
    AGridCellHeader,
  },
  props: {
    /**
     * unique Grid Table Key
     */
    gridKey: {
      type: String,
      required: true,
    },
    /**
     * Defines the total number of data items in all pages
     */
    total: {
      type: Number,
      default: -1,
    },
    page: {
      type: Number,
      default: 1,
    },
    /**
     * Enable/Disable loader
     */
    isLoading: {
      type: Boolean,
      default: false,
    },
    /**
     * Headers for grid
     */
    headers: {
      type: Array,
      required: true,
    },
    /**
     * Data for grid
     */
    dataItems: {
      type: Array,
      required: true,
    },
    /**
     * Is need to hover effect on row
     */
    isDisabledRowHover: {
      type: Boolean,
      default: true,
    },
    /**
     * Is copy to clipboard button shown
     */
    isShowCopyButton: {
      type: Boolean,
      default: true,
    },
    /**
     * Is need to show current page change
     */
    isShowCurrentPage: {
      type: Boolean,
      default: true,
    },
    /**
     * Is uppercase header
     */
    isHeaderUppercase: {
      type: Boolean,
      default: true,
    },
    /**
     * Is need to show current page change
     */
    isHideFooter: {
      type: Boolean,
      default: false,
    },
    /**
     * Is show Footer left corner Cols and Rows text
     */
    isShowFooterColsRows: {
      type: Boolean,
      default: true,
    },
    /**
     * Text in left bottom part of the footer
     */
    footerPrependText: {
      type: String,
      default: '',
    },
    /**
     * Is need to show data without pagination in one column, also separate need hide footer
     */
    isDisablePagination: {
      type: Boolean,
      default: false,
    },
    /**
     * Is need to show current page change
     */
    noRecordsMessage: {
      type: String,
      default: () => 'No records found',
    },
    /**
     * Sorted by column. Can be used with .sync
     */
    sortBy: {
      type: String,
      default: () => null,
    },
    /**
     * Sorting Descending true/false. Can be used with .sync
     */
    sortDesc: {
      type: Boolean,
      default: () => null,
    },
    /**
     * Resize columns true/false
     */
    resizable: {
      type: Boolean,
      default: () => null,
    },
    /**
     * Possibility to preview cell text if it more than 1000 chars true/false
     */
    isCellSelectable: {
      type: Boolean,
      default: () => false,
    },
    /**
     * AQA attribute
     */
    atAttribute: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const defaultPageSizes = [
      {value: 5, title: '5'},
      {value: 10, title: '10'},
      {value: 20, title: '20'},
      {value: 50, title: '50'},
    ]
    const sortByValue = computed({
      get: () => props.sortBy,
      set: (value) => emit('update:sortBy', value),
    })
    const sortDescValue = computed({
      get: () => props.sortDesc,
      set: (value) => emit('update:sortDesc', value),
    })

    const footerProps = {
      showFirstLastPage: true,
      itemsPerPageOptions: defaultPageSizes,
    }

    const gridKey = computed(() => props.gridKey)
    const {
      gridOptions,
      saveGridOptionsToStorage,
    } = useGridStorage(gridKey)
    const defaultRows = computed(() => gridOptions.value.itemsPerPage)
    const currentPage = computed({
      get: () => gridOptions.value.page,
      set: (value) => { gridOptions.value.page = Number.parseInt(value) },
    })
    const currentItemsPerPage = ref(10)
    function itemsPerPageHandler(value) {
      currentItemsPerPage.value = value
    }

    const DEFAULT_TOTAL_ITEMS_COUNT = -1
    const totalItems = computed(() => {
      return props.total !== DEFAULT_TOTAL_ITEMS_COUNT ? props.total : props.dataItems.length
    })

    const gridClass = computed(() => ({
      'grid__row-hover--disabled': props.isDisabledRowHover,
    }))

    function getColumnStyle(col) {
      return {
        width: col.width && `${col.width}%`,
        minWidth: col.minWidth && `${col.minWidth}px`,
      }
    }

    function pageChangeHandler(event) {
      console.log('pageChangeHandler', event)
      const { page, itemsPerPage, sortBy } = event
      const start = (page - 1) * itemsPerPage
      const pageCount = Math.ceil(totalItems.value / itemsPerPage)
      itemsPerPageHandler(pageCount)
      saveGridOptionsToStorage()

      const params = {
        page: page,
        pageCount,
        first: start,
        rows: itemsPerPage,
      }
      /**
       * Emitted when page change
       */
      emit(EVENT_PAGE_CHANGE, params)
    }

    function rowActionHandler(rowIndex, { action, rowDataItem = {} }, dataItem) {
      const idx = defaultRows.value * (currentPage.value - 1) + rowIndex
      const params = {
        rowIndex: idx,
        action,
        rowDataItem,
        dataItem,
      }
      /**
       * Emitted when dropdown item click
       * @property { Object } action
       */
      emit(EVENT_ROW_ACTION, params)
    }

    function changePage(e) {
      let value = !e.target.value ? 1 : parseInt(e.target.value)
      if (value > currentItemsPerPage.value) {
        e.target.value = currentItemsPerPage.value
        value = currentItemsPerPage.value
      } else if (value < 1) {
        e.target.value = 1
        value = 1
      }
      currentPage.value = value
    }

    function handleSort(value) {
      const sortBy = value
      const sortDesc = !sortDescValue.value
      sortByValue.value = sortBy
      sortDescValue.value = sortDesc
      emit(EVENT_SORT_CHANGE, {
        sortBy,
        sortDesc,
      })
    }

    function onCellCopy(column, row, text) {
      emit(EVENT_CELL_COPY, { column, row, text })
    }

    function onCellPreview(column, row, text) {
      emit(EVENT_CELL_PREVIEW, { column, row, text })
    }

    function selectCell(isCellSelected, column, row, text) {
      const cell = isCellSelected ? { column, row, text } : null
      emit(EVENT_CELL_SELECT, cell)
    }

    onMounted(() => {
      if (props.resizable) {
        useGridResize()
      }
    })

    return {
      sortByValue,
      sortDescValue,
      gridClass,
      totalItems,
      defaultRows,
      footerProps,
      gridOptions,
      currentPage,
      defaultPageSizes,
      EVENT_SORT_CHANGE,
      EVENT_CELL_SELECT,
      CELL_PREVIEW_MIN_CHARS,
      selectCell,
      handleSort,
      changePage,
      onCellCopy,
      onCellPreview,
      getColumnStyle,
      itemsPerPageHandler,
      rowActionHandler,
      pageChangeHandler,
    }
  },
})
</script>

<style lang="scss">
@import "o-grid";
</style>
