import { useUrl } from '../composables/use-url'
import backendApiClient, { API_BASE_URL } from './backend-api-client'

const ENDPOINTS = {
  DOWNLOAD: ({ path, apiKeyUuid }) => `/download?path=${path}&apiKeyUuid=${apiKeyUuid}`,
}

export const download = (payload) => (
  backendApiClient.get(ENDPOINTS.DOWNLOAD(payload))
)

const { createUrl } = useUrl()

export const linkToDownloadFile = ({ token, ...args }) => {
  const url = createUrl(API_BASE_URL, ENDPOINTS.DOWNLOAD(args), token)
  return url.href
}
