<template>
  <span class="a-icon">
    <!-- <VueSvgIcon
      :key="name"
      :data="iconData"
      :scale="scale"
      :width="width"
      :height="height"
      :color="color"
      :original="isOriginal"
      :at-a-icon="atAttribute"
      :class="directionClass"
      v-bind="$attrs"
    /> -->
  </span>
</template>

<script>
import {
  ref,
  watch,
  computed,
} from 'vue'

// import { VueSvgIcon } from '@yzfe/vue-svgicon'

import {
  DIRECTION,
  ICON_DIRECTIONS_LIST,
} from '@/shared/constants/placement-direction-constants'

import iconsMap from '@/shared/assets/icons/icons-map'

export default {
  name: 'AIcon',
  components: {
    // VueSvgIcon,
  },
  props: {
    /**
     * File name (includes path and without extensions)
     *
     * The root directory for this one will be: assets/img or check it in the package.json file
     * example: icons/question-mark
     */
    name: {
      type: String,
      validator: val => !val.match(/[.]/g),
      default: '',
    },
    /**
     * Element colors
     */
    color: {
      type: String,
      default: '',
    },
    /**
     * Element width
     *
     * by default it will set size points in 'px'
     * example: 20 / 20px / 20rem
     */
    width: {
      type: String,
      default: '',
    },
    /**
     * Element height
     *
     * by default it will set size points in 'px'
     * example: 20 / 20px / 20rem
     */
    height: {
      type: String,
      default: '',
    },
    /**
     * Scale
     *
     * It will scale size of the icon
     * example: '1', '1.5'...
     */
    scale: {
      type: String,
      // eslint-disable-next-line no-void
      default: void (0),
    },
    /**
     * Enable/Disable svg original color
     */
    isOriginal: {
      type: Boolean,
      default: false,
    },
    /**
     * Rotate icon
     */
    direction: {
      type: String,
      default: DIRECTION.UP,
      validator: val => ICON_DIRECTIONS_LIST.includes(val),
    },
    /**
     * Set an attribute value for AQA
     */
    atAttribute: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const iconData = ref()
    const directionClass = computed(() => props.direction ? `svg-${props.direction}` : null)

    // function getIconData() {
    //   console.log('props.name', props.name)
    //   console.log('iconsMap', iconsMap)
    //   console.log('iconsMap[props.name]', iconsMap[props.name])
    //   iconData.value = require(iconsMap[props.name])
    // }

    // watch(() => props.name, getIconData, { immediate: true })

    return {
      iconData,
      directionClass,
    }
  },
}
</script>

<style lang="scss">
@import "./a-icon";
</style>
