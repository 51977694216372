<template>
  <div class="a-loader">
    <v-progress-circular
      v-if="isShowCircular"
      :indeterminate="indeterminate"
      :size="size"
      :width="width"
      :value="loaderPosition"
    />
    <v-progress-linear
      v-else
      :indeterminate="indeterminate"
      :height="height"
      :value="loaderPosition"
      class="loader__linear"
    />
  </div>
</template>

<script>
import { computed } from 'vue'

const LOADER_TYPES = {
  CIRCULAR: 'circular',
  LINEAR: 'linear',
}
export default {
  name: "ALoader",
  props: {
    loaderType: {
      type: String,
      default: LOADER_TYPES.CIRCULAR,
      validate: (val) => Object.values(LOADER_TYPES).includes(val),
    },
    /**
     * Loader size in font size
     */
    size: {
      type: Number,
      default: 32,
    },
    /**
     * Circular loader inner line width in px
     */
    width: {
      type: Number,
      default: 4,
    },
    /**
     * Linear loader height in px
     */
    height: {
      type: Number,
      default: 2,
    },
    /**
     * Is loader value indeterminate
     */
    indeterminate: {
      type: Boolean,
      default: true,
    },
    /**
     * Current loader position in %
     */
    loaderPosition: {
      type: Number,
      default: 100,
    },
  },
  setup(props) {
    const isShowCircular = computed(() => props.loaderType === LOADER_TYPES.CIRCULAR)

    return {
      isShowCircular,
    }
  },
}
</script>

<style lang="scss">
@import "a-loader";
</style>
