import { randomString } from '@/shared/utils/randomString'
import { useDataViewerPreparePath } from './use-data-viewer-prepare-path'
import { DATA_LOAD_STRATEGIES, DATASET_SOURCE_TYPES, DEFAULT_COUNT_SAMPLE_ROWS } from '@/shared/constants/data-viewer-constants'

export const useDataViewer = () => {
  const { preparePath } = useDataViewerPreparePath()

  const datasetPropsStats = {
    limit: null,
    limitType: null,
    isFullDataset: true,
    datasetType: DATASET_SOURCE_TYPES.STATS,
  }
  const datasetPropsSample = {
    limit: DEFAULT_COUNT_SAMPLE_ROWS,
    limitType: DATA_LOAD_STRATEGIES.TOP,
    isFullDataset: false,
    datasetType: DATASET_SOURCE_TYPES.PERSISTED,
  }
  const datasetPropsFullData = {
    limit: null,
    limitType: null,
    isFullDataset: true,
    datasetType: DATASET_SOURCE_TYPES.PERSISTED,
  }

  const connectorConfig = (connector, datasetProps, isReload) => {
    const reload = isReload ? {
      reloadId: randomString(),
    } : {}

    return {
      ...connector,
      ...datasetProps,
      ...reload,
      ...preparePath({
        ...connector,
        ...datasetProps,
      }),
    }
  }
  const connectorConfigStats = (connector, isReload = false) => {
    return connectorConfig(connector, datasetPropsStats, isReload)
  }
  const connectorConfigSample = (connector, isReload = false) => {
    return connectorConfig(connector, datasetPropsSample, isReload)
  }
  const connectorConfigFullData = (connector, isReload = false) => {
    return connectorConfig(connector, datasetPropsFullData, isReload)
  }

  return {
    datasetPropsStats,
    datasetPropsSample,
    datasetPropsFullData,
    connectorConfigStats,
    connectorConfigSample,
    connectorConfigFullData,
  }
}

export default useDataViewer
