<template>
  <div
    class="a-button-cancel"
    :class="buttonClass"
    @click="onClick"
  >
    <v-icon
      icon="mdi-stop-circle-outline"
      color="red"
      size="60"
    ></v-icon>
  </div>
</template>

<script setup>
import { computed, defineProps, defineEmits } from 'vue';

const props = defineProps({
  disabled: {
    type: Boolean,
    default: () => false,
  },
})
const emit = defineEmits(['click'])
const buttonClass = computed(() => ({
  'button-send--disabled': props.disabled,
}))
const onClick = () => {
  if (props.disabled) return
  emit('click')
}
</script>

<style lang="scss">
@import "a-button-cancel.scss";
</style>