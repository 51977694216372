<template>
  <v-progress-linear
    v-if="message.isLoadingCharts"
    indeterminate
  />
  <div
    v-if="charts.length > 0"
    class="o-message__charts"
  >
    <iframe
      v-for="(chart, index) in charts"
      :srcdoc="chart"
      width="100%"
      :key="index"
    />
  </div>
</template>

<script setup>
import { defineProps, onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

import { download } from '@/shared/api/download.api';

const props = defineProps({
  message: {
    type: Object,
    required: true
  }
});
const route = useRoute()
const isChartsLoading = ref(false)
const charts = ref([])

async function handleDownloadChart(filePath) {
  const apiKeyUuid = route.query.apiKeyUuid
  try {
    const params = {
      path: filePath,
      apiKeyUuid,
    }
    const response = await download(params)
    const chartHtml = response?.data
    return chartHtml
  } catch (error) {
    return null
  }
}

function injectCSSIntoHTMLString(htmlString, cssString) {
  const styleTag = `<style>${cssString}</style>`;
  const headEndIndex = htmlString.indexOf('</head>');
  if (headEndIndex !== -1) {
    return htmlString.slice(0, headEndIndex) + styleTag + htmlString.slice(headEndIndex);
  } else {
    return `<head>${styleTag}</head>` + htmlString;
  }
}

// CSS string to be injected
const cssString = 'body { margin: 0 !important; padding: 0 !important; }';

const prepareCharts = async () => {
  if (!props.message.charts) {
    return
  }
  isChartsLoading.value = true
  for (const chart of props.message.charts) {
    if (chart.html) {
      const htmlString = injectCSSIntoHTMLString(chart.html, cssString);
      charts.value.push(htmlString)
      continue
    }
    const chartHtml = await handleDownloadChart(chart.path)
    if (chartHtml) {
      const htmlString = injectCSSIntoHTMLString(chartHtml, cssString);
      charts.value.push(htmlString)
    }
  }
  isChartsLoading.value = false
}

watch(() => props.message.charts, () => {
  prepareCharts()
}, { deep: true })

onMounted(() => {
  prepareCharts()
})
</script>