<template>
  <div class="a-grid-cell-bar">
    <AGridCellBarItem
      v-for="item in menuItems"
      :key="item.event"
      :item="item"
      @click="handleClick(item.event)"
    />
  </div>
</template>

<script>
import AGridCellBarItem from './a-grid-cell-bar-item'
import { menuItems } from './menu-items'

export default {
  name: 'AGridCellBar',
  components: {
    AGridCellBarItem,
  },
  setup(props, { emit }) {
    const handleClick = (event) => {
      emit(event)
    }

    return {
      menuItems,
      handleClick,
    }
  },
}
</script>

<style lang="scss">
  @import "a-grid-cell-bar";
</style>
