export const useValidators = () => {
  const DEFAULT_MIN_VALIDATOR_STEP = 0.01

  // all this params come from inputData
  // src/components/organisms/o-brick-settings-constructor/use.js
  // src/components/organisms/o-brick-settings-constructor/a-brick-checkbox-controlled-input-field.vue
  function toIntegerValidator(value, params = { min: null, max: null }) {
    if (value === '' || value == null) {
      return null
    }
    if (params.min !== null) {
      if (value < params.min) {
        return params.min
      }
    }
    if (params.max !== null) {
      if (value > params.max) {
        return params.max
      }
    }
    return parseInt(value, 10)
  }

  function toPositiveIntegerValidator(value) {
    value = String(value).replaceAll(/-/g, '').replaceAll(/\+/g, '')
    return toIntegerValidator(value)
  }

  function isFloatingPointZero(value) {
    // ^ matches the start of the string.
    // -? matches an optional minus sign (-).
    // \d+ matches one or more digits (\d).
    // \. matches a decimal point (.) character.
    // 0* matches zero or more zeros.
    // $ matches the end of the string.
    return /^-?\d+\.0*$/.test(value)
  }
  function isDotAnyNumberAndZeroAtEnd(value) {
    // this regex need when we want write 0.010 -> because parseFloat convert 0.010 to 0.01
    // ^ matches the start of the string.
    // \d includes the numbers from 0 to 9. It is equivalent to the character class [0-9].
    // 0\. matches a literal "0" followed by a dot "."
    // \d* matches zero or more digits.
    // 0*$ matches zero or more zeros at the end of the string.
    // $ matches the end of the string.
    return /^(\d\.\d*0)$/.test(value)
  }
  function isDotAndZeroes(value) {
    // ^ matches the start of the string.
    // \. matches a decimal point (.) character.
    // 0* matches zero or more zeros.
    // $ matches the end of the string.
    return /^\.0*$/.test(value)
  }

  // if you prop :is-min-included="false" use convertValidatorExceptionToFloat(value, { excludeZeroWithPoint: true }) at transformProperties()
  function toFloatValidator(value, params = {
    min: null,
    max: null,
    isMinIncluded: true,
    isMaxIncluded: true,
  }) {
    if (value === '' || value == null) {
      return null
    }
    if (value === '-0' || value === '-0.') {
      return value
    }
    if (isFloatingPointZero(value) || isDotAndZeroes(value) || isDotAnyNumberAndZeroAtEnd(value)) {
      return value
    }
    if (params.max !== null) {
      if (params.isMaxIncluded) {
        if (value > params.max) {
          return params.max
        }
      // Max is Not Included
      } else if (value >= params.max) {
          return params.max - DEFAULT_MIN_VALIDATOR_STEP
        }
    }
    if (params.min !== null) {
      if (params.isMinIncluded) {
        // this check need because we can't input value 0,001
        // we must also ensure that the user does not enter a negative value like -0,001
        if (value < params.min) {
          if (value === '0') {
            return parseFloat(value)
          }
          return params.min
        }
      // Min is Not Included
      } else if (value <= params.min) {
        if (value === String(params.min)) {
          return value
        }
        return params.min + DEFAULT_MIN_VALIDATOR_STEP
      }
    }
    return parseFloat(value)
  }

  function toStringValidator(value) {
    if (value === '' || value == null) {
      return null
    }
    return toString(value)
  }

  function toTimeValidator(value) {
    if (value === '' || value == null) {
      return null
    }
    const isValid = /^\d?\d?:?\d?\d?$/.test(value)
    if (isValid) {
      return value
    }
    return '00:00'
  }

  // TODO: in future we must eliminate this method, to correct float validation
  // Maybe we need new contract between frontend and pipeline, where we can pass { type: 'float'}
  // use convertValidatorExceptionToFloat(value, { excludeZeroWithPoint: true }) at transformProperties() if you prop :is-min-included="false"
  const convertValidatorExceptionToFloat = (value, validationParams = { excludeZeroWithPoint: false }) => {
    if (value === null) {
      return null
    }
    if (typeof value === 'number') {
      return value
    }
    if (validationParams.excludeZeroWithPoint) {
      // ^ matches the start of the string.
      // -? matches an optional minus sign (-).
      // \d+ matches one or more digits (\d).
      // \. matches a decimal point (.) character.
      // 0* matches zero or more zeros.
      // $ matches the end of the string.
      const isZeroWithDot = /^-?0\.0*$/.test(value)
      if (isZeroWithDot || value === '0' || value === '-0' || isDotAndZeroes(value)) {
        return null
      }
    }
    if (isFloatingPointZero(value) || value === '-0' || value === '-0.') {
      return parseFloat(value)
    }
    return parseFloat(value)
  }

  return {
    DEFAULT_MIN_VALIDATOR_STEP,
    toFloatValidator,
    toStringValidator,
    toIntegerValidator,
    toTimeValidator,
    isFloatingPointZero,
    isDotAndZeroes,
    toPositiveIntegerValidator,
    convertValidatorExceptionToFloat,
  }
}

export default useValidators
