<template>
  <div
    class="a-grid-cell-bar__item"
    @click="handleClick"
  >
    <AIcon
      is-original
      :name="item.icon"
    />
  </div>
</template>

<script>
import AIcon from '@/shared/components/a-icon/'
import { EVENT_CLICK } from '@/shared/constants/bus-events-constants'

export default ({
  name: 'AGridCellBarItem',
  components: {
    AIcon,
  },
  props: {
    /**
     * Brick actions menu item
     */
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const handleClick = () => {
      emit(EVENT_CLICK)
    }

    return {
      handleClick,
    }
  },
})
</script>

<style lang="scss">
@import "a-grid-cell-bar-item";
</style>
