<template>
  <div>
    <AIconAnalystProfile v-if="!isUserMessage && isDatricsIcon" :icon-path="iconPath" />
    <AIconUser v-else />
  </div>
</template>

<script setup>
import { defineProps } from 'vue';
import AIconAnalystProfile from '../a-icon-analyst-profile'
import AIconUser from '../a-icon-user';
import {
  CLIENT_NAME,
  CLIENT_NAMES,
} from '../../constants'

defineProps({
  isUserMessage: {
    type: Boolean,
    required: true
  },
  iconPath: {
    type: String,
    default: '',
  },
})

const DATRICS_ICONS = [
  CLIENT_NAMES.CFO,
  CLIENT_NAMES.DATRICS,
  CLIENT_NAMES.CFO_TBI,
  CLIENT_NAMES.SIGMA,
]
const isDatricsIcon = DATRICS_ICONS.includes(CLIENT_NAME)
</script>