// import { useRoute } from '@/router/use'
// import {
//   useAuth,
//   useCurrentProjectPipeline,
// } from '@/store/use'
import {
  DATASET_SOURCE_TYPES, DATA_LOAD_STRATEGIES, DEFAULT_COUNT_SAMPLE_ROWS,
} from '@/shared/constants/data-viewer-constants'

export const useDataViewerPreparePath = () => {
  // const route = useRoute()
  // const { userId } = useAuth()
  // const {
  //   currentProjectPipelineBasePath,
  // } = useCurrentProjectPipeline()

  // const addPrefixToPath = (path) => {
  //   const projectId = route.params.projectId
  //   const projectPathPrefix = `${userId.value}/${projectId}/`

  //   if (!path) {
  //     return path
  //   }
  //   if (path.includes(projectPathPrefix)) {
  //     return path
  //   }
  //   if (path.includes(currentProjectPipelineBasePath.value)) {
  //     return path
  //   }
  //   if (path.includes('samples/') && (path.includes('.csv') || path.includes('.parquet'))) {
  //     return path
  //   }
  //   return `${currentProjectPipelineBasePath.value}${path}`
  // }

  const preparePath = (config) => {
    const isPersisted = config.datasetType === DATASET_SOURCE_TYPES.PERSISTED
    const isOptimized = config.totalRows < DEFAULT_COUNT_SAMPLE_ROWS || (config.limit <= DEFAULT_COUNT_SAMPLE_ROWS && config.limitType === DATA_LOAD_STRATEGIES.TOP)
    const isNotRun = !config.persisted_data_set_path && !config.path && !config.stats_path
    const isDataset = isNotRun && config.sample_path
    const isFullDataset = config.isFullDataset

    if (config.isDataAppPreview) {
      return {
        targetPath: config.path,
        datasetType: DATASET_SOURCE_TYPES.PERSISTED,
      }
    }
    // if (isPersisted && isOptimized) {
    //   return {
    //     targetPath: addPrefixToPath(config.sample_path),
    //     datasetType: DATASET_SOURCE_TYPES.PERSISTED,
    //   }
    // }
    if (isPersisted && isFullDataset) {
      return {
        targetPath: config.persisted_data_set_path,
        datasetType: DATASET_SOURCE_TYPES.PERSISTED,
      }
    }
    // if (isPersisted && isDataset && isOptimized) {
    //   return {
    //     targetPath: addPrefixToPath(config.sample_path),
    //     datasetType: DATASET_SOURCE_TYPES.PERSISTED,
    //   }
    // }
    if (isPersisted) {
      return {
        targetPath: config.persisted_data_set_path,
        datasetType: DATASET_SOURCE_TYPES.PERSISTED,
      }
    }
    // if (!isPersisted) {
    //   return {
    //     targetPath: addPrefixToPath(config.stats_path),
    //     datasetType: DATASET_SOURCE_TYPES.STATS,
    //   }
    // }
  }

  return {
    preparePath,
    // addPrefixToPath,
  }
}
