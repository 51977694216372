<template>
  <div class="container-body">
    <OSidebar
      v-if="showSidebar"
      @onChangeConversation="onChangeConversation"
    />
    <OChatBody
      :conversation="selectedConversation"
    />
  </div>
</template>

<script setup>
import { ref } from 'vue';

import OSidebar from '../../components/o-sidebar'
import OChatBody from '../../components/o-body'
import { useRoute } from 'vue-router';

const route = useRoute()
const selectedConversation = ref(null)
const showSidebar = ref(route.query.showSidebar !== 'false')

const onChangeConversation = (item) => {
  selectedConversation.value = item
  console.log(selectedConversation.value)
}

</script>

<style lang="scss">
.container-body {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 1px;
  padding: 1px;
  box-sizing: border-box;
}

.o-header + .container-body {
  height: calc(100% - 40px);
}
</style>