export default {
  name: 'CellRender',
  functional: true,
  props: {
    /**
     * value name
     */
    value: {
      type: String,
      required: true,
    },
    /**
     * Column object
     */
    column: {
      type: Object,
      required: true,
      validator: (item) => Boolean(item.cellRender),
    },
    /**
     * Grid row Data
     */
    dataItem: {
      type: Object,
      required: true,
    },
    /**
     * Is sticky column
     */
    isLocked: {
      type: Boolean,
      default: false,
    },
  },
  render(h, { props, listeners }) {
    const isComponent = typeof props.column.cellRender !== 'function'
    function createElementWrapper(cellComponent, cellProps) {
      const localProps = {
        ...cellProps,
        value: props.value,
        dataItem: props.dataItem,
      }
      return h(cellComponent, { props: localProps, on: { ...listeners } })
    }
    return isComponent
      ? createElementWrapper(props.column.cellRender, {})
      : props.column.cellRender(createElementWrapper, props)
  },
}
