<template>
  <div class="o-message__menu">
    <AIconThumbsNew
      direction="up"
      :selected="isSelected == true"
      @click="() => $emit('select', true)"
    />
    <AIconThumbsNew
      direction="down"
      :selected="isSelected == false"
      @click="() => $emit('select', false)"
    />
  </div>
</template>

<script setup>
import { computed, defineProps } from 'vue';

import AIconThumbsNew from '../a-icon-thumbs-new';

const props = defineProps({
  message: {
    type: Object,
    default: () => ({})
  }
})
const isSelected = computed(() => props.message.feedback?.liked);
</script>

<style lang="scss">
.o-message__menu {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 15px;
  margin-bottom: 10px;
}
</style>