<template>
  <v-menu
    :open-on-hover="Boolean(item.children)"
    :open-on-click="false"
    :open-on-focus="false"
    :left="isChildrenLeft"
    :right="isChildrenRight"
    :offset-x="true"
    max-height="350"
    content-class="o-menu"
  >
    <template #activator="{ props }">
      <div class="o-menu-item__container">
        <div
          v-if="item.hasDivider"
          class="menu-item__divider"
        />
        <ATooltip
          :content="tooltipContent"
          placement="left"
        >
          <div
            v-bind="props"
            class="o-menu-item"
            :class="itemClass"
            :style="itemStyle"
            @click="handleItemSelect(item.value)"
          >
            <span
              class="menu-item__icon-children"
              :class="iconClass"
            >
              <AIcon
                v-if="item.children"
                :is-original="true"
                :direction="arrowDirection"
                width="5"
                height="10"
                name="icons/arrow-left"
              />
            </span>
            <span
              class="menu-item__item-icon"
              :class="iconClass"
            >
              <AIcon
                v-if="item.icon"
                :is-original="true"
                :name="`${item.icon}`"
                :height="`${item.iconHeight || 0}`"
                :width="`${item.iconHeight || 0}`"
              />
            </span>
            <span
              v-if="columnType"
              class="menu-item__column-type"
            >
              {{ columnType }}
            </span>
            <div
              class="menu-item__item-title"
              :style="titleStyleOptions"
              v-html="item.title"
            />
            <slot name="menu-item-right" :slot-item="item" />
          </div>
        </ATooltip>
      </div>
    </template>
    <div
      v-show="item.children"
      class="menu__sub-item"
    >
      <OMenuSubItem
        v-for="subItem in item.children"
        :key="(subItem.value || subItem) + Math.random()"
        :item="subItem"
        :sub-item-value="menuSubItemValue"
        @clicked="handleSubItemSelect"
      />
    </div>
  </v-menu>
</template>

<script>
import { computed, defineComponent } from 'vue'

import AIcon from '@/shared/components/a-icon'
import OMenuSubItem from './o-menu-sub-item.vue'

import { DIRECTION } from '@/shared/constants/placement-direction-constants'
import {
  EVENT_SELECTED,
  EVENT_SELECTED_SUB,
} from '@/shared/constants/bus-events-constants'
import { SHORT_TYPES } from '@/shared/constants/data-types-constants'
import ATooltip from '@/shared/components/a-tooltip'

export default defineComponent({
  name: 'OMenuItem',
  components: {
    AIcon,
    OMenuSubItem,
    ATooltip,
  },
  props: {
    menuValue: {
      type: [String, Number, Boolean],
      default: () => '',
    },
    menuSubItemValue: {
      type: [String, Number, Boolean],
      default: () => '',
    },
    item: {
      type: Object,
      required: true,
    },
    maxWidth: {
      type: Number,
      default: 0,
    },
    /**
     * if true at list item title will shorter it need for showing type value
     */
    isEnableListPaddingRight: {
      type: Boolean,
      default: () => false,
    },
    /**
     * if true show full item.title on hover
     */
    isShowTooltip: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props, { emit }) {
    const arrowDirection = props.item.isIconLeft ? DIRECTION.UP : DIRECTION.DOWN
    const itemStyle = {
      height: props.item.height ? props.item.height : '28px',
      paddingLeft: (props.item.icon && props.item.isIconLeft) || (props.item.children && props.item.isIconLeft) ? '26px' : '15px',
      paddingRight: (props.item.icon && !props.item.isIconLeft) || (props.item.children && !props.item.isIconLeft) ? '26px' : '15px',
    }
    const iconClass = {
      'menu-item__icon--left': props.item.isIconLeft,
    }
    const itemClass = computed(() => ({
      'menu-item--selected': props.menuValue === props.item.value,
    }))
    const isChildrenLeft = computed(() => props.item.children && props.item.isIconLeft)
    const isChildrenRight = computed(() => props.item.children && !props.item.isIconLeft)

    const columnType = computed(() => {
      const type = SHORT_TYPES[props.item?.columnType?.toUpperCase()] || ''
      return type
    })

    const tooltipContent = computed(() => {
      const tooltip = props.isShowTooltip ? props.item.title : ''
      return tooltip
    })

    const titleStyleOptions = computed(() => {
      return {
        'width': props.maxWidth && props.isEnableListPaddingRight ? `${props.maxWidth - 75}px` : '100%',
      }
    })

    function handleSubItemSelect(value) {
      const params = {
        parentItem: props.item.value,
        value: value,
      }
      emit(EVENT_SELECTED_SUB, params)
    }

    function handleItemSelect(value) {
      emit(EVENT_SELECTED, value)
    }
    return {
      columnType,
      itemClass,
      itemStyle,
      iconClass,
      DIRECTION,
      tooltipContent,
      isChildrenLeft,
      isChildrenRight,
      arrowDirection,
      titleStyleOptions,
      handleItemSelect,
      handleSubItemSelect,
    }
  },
})
</script>

<style lang="scss">
@import "o-menu-item";
</style>
