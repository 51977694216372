function getItemFromLocalStorage(key) {
  return JSON.parse(window.localStorage.getItem(key))
}

function setItemToLocalStorage(key, value) {
  if (typeof value === 'object') {
    window.localStorage.setItem(key, JSON.stringify(value))
  } else {
    window.localStorage.setItem(key, value)
  }
}

function removeItemFromLocalStorage(key) {
  window.localStorage.removeItem(key)
}

function getItemFromSessionStorage(key) {
  return JSON.parse(window.sessionStorage.getItem(key))
}

function setItemToSessionStorage(key, value) {
  try {
    if (typeof value === 'object') {
      window.sessionStorage.setItem(key, JSON.stringify(value))
    } else {
      window.sessionStorage.setItem(key, value)
    }
  } catch (e) {
    console.error('Exceeded the quota')
  }
}

function mergeDataObjectToSessionStorage(key, newObj) {
  const oldObj = getItemFromSessionStorage(key)
  const preparedValue = JSON.stringify({
    ...oldObj,
    ...newObj,
  })
  window.sessionStorage.setItem(key, preparedValue)
}

function removeItemFromSessionStorage(key) {
  window.sessionStorage.removeItem(key)
}

export {
  setItemToLocalStorage,
  getItemFromLocalStorage,
  removeItemFromLocalStorage,
  getItemFromSessionStorage,
  setItemToSessionStorage,
  mergeDataObjectToSessionStorage,
  removeItemFromSessionStorage,
}
