<template>
  <div class="o-sidebar">
    <div class="sidebar__header">
      <p>
        Recent chats
      </p>
      <ATooltip
        content="New chat"
      >
        <AButtonAdd
          class="sidebar__button-new-chat"
          @click="onClickNewChat"
        />
    </ATooltip>
    </div>
    <div class="sidebar__chats">
      <AConversationItem
        v-for="item in conversations"
        :key="item.uuid"
        @click="onClickItem(item)"
      >
        {{ item.title }}
      </AConversationItem>
    </div>
  </div>
</template>

<script setup>
import * as uuid from 'uuid'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { defineEmits, computed } from 'vue'
import router from '../../router'

import AConversationItem from './a-conversation-item'
import AButtonAdd from '@/components/a-button-add'
import ATooltip from '@/shared/components/a-tooltip'

const route = useRoute()
const store = useStore()
const conversations = computed(() => store.state.conversations.conversations)

const emit = defineEmits(['onChangeConversation'])
const onClickItem = (item) => {
  router.push({ name: 'chat', query: { ...route.query, conversationUuid: item.uuid } })
  emit('onChangeConversation', item)
}
const onClickNewChat = () => {
  onClickItem({ uuid: uuid.v4() })
}

</script>

<style lang="scss">
@import "o-sidebar.scss";
</style>
