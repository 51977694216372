<template>
  <div class="a-checkbox">
    <v-checkbox
      :input-value="value"
      :disabled="isDisabled"
      :dense="true"
      :hide-details="true"
      class="a-checkbox"
      @change="handleChange"
    />
    <div class="a-checkbox__text">
      {{ text }}
    </div>
    <ATooltip
      v-if="tooltip"
      :content="tooltip"
    >
      <AIcon
        class="brick-feature__tooltip"
        name="icons/tooltip"
        is-original
      />
    </ATooltip>
  </div>
</template>

<script>
import { EVENT_CHANGE, EVENT_INPUT } from '@/shared/constants/bus-events-constants'
import ATooltip from '@/shared/components/a-tooltip'
import AIcon from '@/shared/components/a-icon'

export default {
  name: "ACheckbox",
  components: {
    AIcon,
    ATooltip,
  },
  props: {
    /**
     * Checkbox value
     */
    value: {
      type: Boolean,
      required: true,
    },
    /**
     * Is checkbox disabled
     */
    isDisabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Is checkbox disabled
     */
    text: {
      type: String,
      default: () => '',
    },
    /**
     * feature tooltip
     */
    tooltip: {
      type: String,
      default: () => '',
    },
  },
  setup(props, { emit }) {
    function handleChange(value) {
      /**
       * Emitted { value } when changed
       */
      emit(EVENT_INPUT, value)
      emit(EVENT_CHANGE, value)
    }

    return {
      handleChange,
    }
  },
}
</script>

<style lang="scss">
@import "a-checkbox";
</style>
