<template>
  <AInputWrapper
    :title="title"
    :tooltip="tooltip"
    :required="required"
    :error="error"
    class="a-input"
    >
    <!-- v-bind="$attrs" -->
    <input
      v-model="inputData"
      :type="type"
      :step="step"
      :min="min"
      :max="max"
      :placeholder="placeholder"
      :disabled="isDisabled"
      :readonly="readonly"
      :style="styleOptions"
      class="input__field"
      :class="inputClass"
      @keydown="onKeyDown"
      @keyup.enter="$emit('enter', inputData)"
    >
    <template #input-icon-right-2>
      <AIcon
        v-if="iconRight"
        :name="iconRight"
        is-original
      />
    </template>
  </AInputWrapper>
</template>

<script>
import {
  computed,
  defineComponent,
} from 'vue'

import AIcon from '@/shared/components/a-icon'
import AInputWrapper from '@/shared/components/a-input-wrapper'

export default defineComponent({
  name: 'AInput',
  components: {
    AIcon,
    AInputWrapper,
  },
  props: {
    /**
     * required - if true, the field is required and marked with red asterisk
     */
    required: {
      type: Boolean,
      default: false,
    },
    /**
     * if true, border will have red border color
     */
    error: {
      type: String,
      default: '',
    },
    /**
     * Value need only for the v-model functionality
     */
    value: {
      type: [String, Number, Boolean],
      default: () => null,
    },
    /**
     * Title
     */
    title: {
      type: String,
      default: '',
    },
    /**
     * isDisabled for the ability to disable or enable selector
     */
    isDisabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Readonly for the ability to disable or enable selector
     */
    readonly: {
      type: Boolean,
      default: false,
    },
    /**
     * Type of the input
     */
    type: {
      type: String,
      default: 'text',
    },
    /**
     * If type is number, user can specify the step
     */
    step: {
      type: String,
      default: '1',
    },
    /**
     * If type is number, user can specify the min number
     */
    min: {
      type: String,
      default: '',
    },
    /**
     * If type is number, user can specify the max number
     */
    max: {
      type: String,
      default: '',
    },
    /**
    * Placeholder text
    */
    placeholder: {
      type: String,
      default: '',
    },
    /**
    * Units text, will display at right part of input
    */
    units: {
      type: String,
      default: '',
    },
    /**
     * You can prop style for example:
     * {
     *   'margin-bottom': `${10}px`,
     * }
     */
    styleOptions: {
      type: Object,
      default: () => ({}),
    },
    /**
     * You can prop here validate function, for example
     *  getValidNumberOfClusters(value) {
          if (value < this.valueOfClusters.min) {
            return this.valueOfClusters.min
          }
          return parseInt(value)
        },
    */
    validator: {
      type: Function,
      default: null,
    },
    /**
     * tooltip - tooltip text
     */
    tooltip: {
      type: String,
      default: '',
    },
    /**
     * iconRight - icon at the right side of input
     */
    iconRight: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const inputData = computed({
      get() {
        return props.value
      },
      set(value) {
        if (value == null) {
          emit('input', value)
        } else if (props.validator) {
          emit('input', props.validator(value))
        } else {
          value = props.type === 'number' ? Number(value) : value
          emit('input', value)
        }
      },
    })

    const onKeyDown = (e) => {
      if (props.validator && props.validator(e.key) == null) {
        e.preventDefault()
      }
    }

    const inputClass = computed(() => ({
      'input__error': props.error,
    }))

    return {
      inputData,
      inputClass,
      onKeyDown,
    }
  },
})
</script>

<style lang="scss">
@import "a-input";
</style>
