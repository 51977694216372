<template>
  <v-menu
    internal-activator
    content-class="o-menu"
    :nudge-bottom="nudgeBottom"
    :nudge-left="nudgeLeft"
    :nudge-right="nudgeRight"
    :min-width="width || minWidth"
    :max-width="width || maxWidth"
    :max-height="300"
  >
    <template #activator="{ props }">
      <div
        ref="menuTriggerButton"
        v-bind="props"
        class="o-menu__button-trigger"
        :class="classTrigger"
        v-on="onTriggered(props)"
      >
        <slot>Open</slot>
      </div>
    </template>
    <template v-if="!isDisabled">
      <span>
        <OMenuItem
          v-for="item in items"
          :key="item.value + Math.random()"
          :item="item"
          :max-width="maxWidth"
          :is-enable-list-padding-right="isEnableListPaddingRight"
          :menu-value="value"
          :menu-sub-item-value="subItemValue"
          :is-show-tooltip="isShowTooltip"
          @selected="handleSelect"
          @selectedSub="handleSelectSubItem"
        >
          <template #menu-item-right="{ slotItem }">
            <slot name="menu-item-right" :slot-item="slotItem" />
          </template>
        </OMenuItem>
      </span>
      <OMenuItemAdditionalInfo
        v-if="additionalMessage?.title || $slots['menu-additional-item-bottom']"
        class="additional-info"
        :item="additionalMessage"
        :max-width="maxWidth"
        :is-additional-message="Boolean(additionalMessage?.title)"
        :is-enable-list-padding-right="isEnableListPaddingRight"
        :menu-value="value"
        :menu-sub-item-value="subItemValue"
      >
        <template #menu-additional-item-bottom>
          <slot name="menu-additional-item-bottom" />
        </template>
      </OMenuItemAdditionalInfo>
    </template>
  </v-menu>
</template>

<script>
import {
  ref,
  watch,
  computed,
} from 'vue'

import OMenuItem from './o-menu-item'
import OMenuItemAdditionalInfo from './o-menu-item-additional-info'

import {
  EVENT_SELECTED,
  EVENT_SELECTED_SUB,
} from '@/shared/constants/bus-events-constants'

export default {
  name: 'OMenu',
  components: {
    OMenuItem,
    OMenuItemAdditionalInfo,
  },
  props: {
    /**
     * Title that display before menu shows. If you click to it, menu will be shown
     */
    value: {
      type: [String, Number, Boolean],
      default: () => '',
    },
    subItemValue: {
      type: [String, Number, Boolean],
      default: () => '',
    },
    /**
     * Array of menu items. Can consist title,  value, children.
     * For example [{ title: "Rename", value: "rename", children: [{...}, {...}] }]
     */
    items: {
      type: Array,
      required: true,
    },
    /**
     * Message displayed at bottom of items.
     * For example { title: "Some info" }
     */
    additionalMessage: {
      type: Object,
      default: () => {
        return {}
      },
    },
    /**
     * Nudge the position of menu to the bottom by 'X'px
     * more: https://vuetifyjs.com/en/api/v-menu/
     */
    nudgeBottom: {
      type: Number,
      default: 0,
    },
    /**
     * Nudge the position of menu to the left by 'X'px
     * more: https://vuetifyjs.com/en/api/v-menu/
     */
    nudgeLeft: {
      type: Number,
      default: 0,
    },
    /**
     * Nudge the position of menu to the right by 'X'px
     * more: https://vuetifyjs.com/en/api/v-menu/
     */
    nudgeRight: {
      type: Number,
      default: 0,
    },
    /**
     * Sets the minimum width for the content 'X'px
     * more: https://vuetifyjs.com/en/api/v-menu/
     */
    minWidth: {
      type: [Number, String],
      default: 160,
    },
    /**
     * Sets the maximum width for the content 'X'px
     * more: https://vuetifyjs.com/en/api/v-menu/
     */
    maxWidth: {
      type: [Number, String],
      default: 400,
    },
    /**
     * Sets the auto width by slot getBoundingClientRect().width
     * more: https://vuetifyjs.com/en/api/v-menu/
     */
    autoWidth: {
      type: Boolean,
      default: false,
    },
    /**
     * if true at list item title will shorter it need for showing type value
     */
    isEnableListPaddingRight: {
      type: Boolean,
      default: () => false,
    },
    /**
     * if true show full item.title on hover
     */
    isShowTooltip: {
      type: Boolean,
      default: () => false,
    },
    /**
     * Disabled
     * more: https://vuetifyjs.com/en/api/v-menu/
     */
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const width = ref(null)
    const menuTriggerButton = ref(null)
    const classTrigger = computed(() => ({
      'o-menu__button-trigger--disabled': props.isDisabled,
    }))

    function handleSelect(value) {
      emit(EVENT_SELECTED, value)
    }

    function handleSelectSubItem(value) {
      menuTriggerButton.value.click()
      emit(EVENT_SELECTED_SUB, value)
    }

    function onTriggered(attrs) {
      if (!props.isDisabled) {
        emit('toggleMenu', attrs['aria-expanded'] === 'true')
        // return on
        return attrs
      }
    }

    watch(menuTriggerButton, () => {
      if (props.autoWidth) {
        width.value = menuTriggerButton.value.getBoundingClientRect().width
      }
    })

    return {
      width,
      classTrigger,
      menuTriggerButton,
      onTriggered,
      handleSelect,
      handleSelectSubItem,
    }
  },
}
</script>

<style lang="scss">
@import "o-menu";
</style>
