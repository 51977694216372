<template>
  <v-menu
    :open-on-hover="Boolean(item.children)"
    :open-on-click="false"
    :open-on-focus="false"
    :offset-x="true"
    max-height="350"
    content-class="o-menu"
  >
    <template #activator="{ props }">
      <div class="o-menu-item__container">
        <div
          v-if="item.hasDivider"
          class="menu-item__divider"
        />

        <div
          v-bind="props"
          class="o-menu-item-additional-info"
        >
          <div
            class="menu-item__item-title"
            v-html="item.title"
          />
          <slot name="menu-additional-item-bottom" />
        </div>
      </div>
    </template>
  </v-menu>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'OMenuItemAdditionalInfo',
  components: {
  },
  props: {
    /**
     * Message displayed at bottom of items.
     * For example { title: "Some info" }
     */
    item: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
    }
  },
})
</script>

<style lang="scss">
@import "o-menu-item-additional-info";
</style>
