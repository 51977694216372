export const CLIENT_NAME = process.env.VUE_APP_CLIENT_NAME
export const SUBST_BOT_NAME = process.env.VUE_APP_SUBST_BOT_NAME || CLIENT_NAME
export const SHOW_SWITCH = process.env.VUE_APP_SHOW_SWITCH === 'true'
export const SHOW_DATA_TAB = process.env.VUE_APP_SHOW_DATA_TAB === 'true'

if (!CLIENT_NAME) {
  throw Error('Provide VUE_APP_CLIENT_NAME')
}
export const CLIENT_NAMES = {
  CFO: 'CFO',
  CFO_TBI: 'CFO_TBI',
  SIGMA: 'CFO_Sigma',
  DATRICS: 'Datrics',
}

export const suggestedQuestionsDatrics = []
export const suggestedQuestionsCfo = [
  "What is the number of monthly applications in Q1 2023?",
  "What is the number of monthly applications in Q1 2023? Add plot",
  "Compare the approval rate of new vs existing applications this year?",
  "What is the trend or approved applications for 2022? Please visualize."
]
export const suggestedQuestionsSigma = [
  "Compare the different projects by overtime efforts and billable efforts",
  "Plot total time effort for each task in the VINDIGO project",
  "List top 5 most time consuming tasks.",
  "Analyze Budget profit vs Actual profit in Q1 2023 and Q2 2023.",
]
const suggestedQuestionsByClient = {
  [CLIENT_NAMES.DATRICS]: suggestedQuestionsDatrics,
  [CLIENT_NAMES.CFO]: suggestedQuestionsCfo,
  [CLIENT_NAMES.CFO_TBI]: suggestedQuestionsCfo,
  [CLIENT_NAMES.SIGMA]: suggestedQuestionsSigma,
}

export const suggestedQuestions = suggestedQuestionsByClient[CLIENT_NAME] || []

export const eventTypes = {
  AI_ANALYST_REFRESH_IFRAME: 'AI_ANALYST_REFRESH_IFRAME',
  AI_ANALYST_DISABLE_IFRAME: 'AI_ANALYST_DISABLE_IFRAME',
  AI_ANALYST_ENABLE_IFRAME: 'AI_ANALYST_ENABLE_IFRAME',
  AI_ANALYST_STARTED_IFRAME: 'AI_ANALYST_STARTED_IFRAME',
  AI_ANALYST_STOPPED_IFRAME: 'AI_ANALYST_STOPPED_IFRAME',
}
