import {
  onBeforeUnmount,
  getCurrentInstance,
} from 'vue'
import mitt from 'mitt'

const emitter = mitt()

export function useBus() {
  const { proxy } = getCurrentInstance() || {}

  function busOn(event, handler) {
    emitter.on(event, handler)
  }
  function busOff(event, handler) {
    emitter.off(event, handler)
  }
  function busEmit(event, value) {
    emitter.emit(event, value)
  }

  function setBusListener(event, handler) {
    if (!proxy) {
      throw Error('Do not use setBusListener outside Vue components')
    }

    busOn(event, handler)

    onBeforeUnmount(() => {
      busOff(event, handler)
    })
  }

  return {
    busOn,
    busOff,
    busEmit,
    setBusListener,
  }
}

export default useBus
