import axios from 'axios'
// import Cookies from 'js-cookie'

export const API_BASE_URL = process.env.VUE_APP_BACKEND_API_BASE_URL || 'https://api.testing.datrics.ai/api/v1'

const apiClient = axios.create({
  baseURL: API_BASE_URL,
})

// const getAuthToken = () => Cookies.get('user-token')
// const authInterceptor = (config) => {
//   config.headers['authorization'] = `Bearer ${getAuthToken()}`
//   return config
// }
// apiClient.interceptors.request.use(authInterceptor)

export default apiClient
