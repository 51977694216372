<template>
  <div
    v-click-outside="unSelect"
    class="a-grid-cell-text"
    :class="classCell"
    :at-a-grid-cell-text="atAttribute"
    @click="onClick"
    @mouseover="() => isCellHovered = true"
    @mouseleave="() => isCellHovered = false"
  >
    <AGridCellBar
      v-if="isCellHovered && isCellSelectable"
      @copy="() => $emit('copy')"
      @preview="() => $emit('preview')"
    />
    <slot />
  </div>
</template>

<script>
import {
  ref,
  computed,
  defineComponent,
} from 'vue'

import AGridCellBar from '../a-grid-cell-bar'

import { EVENT_CELL_SELECT } from '@/shared/constants/bus-events-constants'

export default defineComponent({
  name: 'AGridCellText',
  components: {
    AGridCellBar,
  },
  props: {
    /**
     * Possibility to preview cell text if it more than 1000 chars true/false
     */
    isCellSelectable: {
      type: Boolean,
      default: () => false,
    },
    /**
     * Possibility to preview cell text if it more than 1000 chars true/false
     */
    isCellHighlighted: {
      type: Boolean,
      default: () => false,
    },
    /**
     * AQA attribute
     */
    atAttribute: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const isCellSelected = ref(false)
    const isCellHovered = ref(false)
    const classCell = computed(() => ({
      'grid-cell-text--selectable': props.isCellSelectable,
      'grid-cell-text--selected': props.isCellSelectable && isCellSelected.value,
      'grid-cell-text--highlighted': props.isCellHighlighted,
    }))

    const onClick = () => {
      isCellSelected.value = !isCellSelected.value
      emit(EVENT_CELL_SELECT, isCellSelected.value)
    }
    const unSelect = () => {
      isCellSelected.value = false
    }

    return {
      classCell,
      isCellSelected,
      isCellHovered,
      onClick,
      unSelect,
    }
  },
})
</script>

<style lang="scss">
@import "a-grid-cell-text";
</style>
