<template>
  <div class="o-menu-button-text">
    <OMenu
      :items="items"
      :value="value"
      :sub-item-value="subItemValue"
      :nudge-bottom="nudgeBottom"
      :nudge-left="nudgeLeft"
      :is-disabled="isDisabled"
      :min-width="minWidth"
      :max-width="minWidth"
      @selected="($event) => $emit('selected', $event)"
      @selectedSub="($event) => $emit('selected', $event)"
    >
      <div
        class="menu-button-text__action-place"
        @click="toggleMenu"
      >
        <div class="menu-button-text__title-place">
          {{ value }}{{ subItemValue ? `: ${subItemValue}` : '' }}
        </div>
        <div
          v-if="!isDisabled"
          class="menu-button-text__arrow-place"
        >
          <AIconArrow :active="isMenuOpen" />
        </div>
      </div>
    </OMenu>
  </div>
</template>

<script>
import { computed, defineComponent, ref } from 'vue'

import OMenu from '@/shared/components/o-menu'
import AIconArrow from '@/shared/components/a-icon-arrow'

export default defineComponent({
  name: 'OMenuButtonText',
  components: {
    OMenu,
    AIconArrow,
  },
  props: {
    value: {
      type: String,
      default: () => '',
    },
    subItemValue: {
      type: String,
      default: () => null,
    },
    items: {
      type: Array,
      default: () => [],
    },
    nudgeBottom: {
      type: Number,
      default: () => 50,
    },
    nudgeLeft: {
      type: Number,
      default: () => 50,
    },
    minWidth: {
      type: Number,
      default: () => 100,
    },
  },
  emits: ['selected'],
  setup(props) {
    const styles = { 'min-width': `${props.minWidth}px` }
    const isMenuOpen = ref(false)
    const isDisabled = computed(() => !props.items.length)

    const toggleMenu = () => {
      if (isDisabled.value) {
        return
      }
      isMenuOpen.value = !isMenuOpen.value
    }

    return {
      styles,
      isMenuOpen,
      isDisabled,
      toggleMenu,
    }
  },
})
</script>

<style lang="scss">
@import "o-menu-button-text";
</style>
