<template>
  <AInputWrapper
    :title="title"
    :tooltip="tooltip"
    :required="required"
    :error="error"
    :has-error="hasError"
    :is-any-changes="isAnyChanges"
    class="a-select-new"
    :style="styleOptions"
    @click="triggerMenu"
  >
    <OMenu
      :value="value"
      :items="itemsFiltered"
      :additional-message="{ title: additionalMessage }"
      :nudge-bottom="28"
      :auto-width="!width"
      :min-width="width"
      :max-width="inputWidth"
      :is-disabled="isDisabled"
      :is-enable-list-padding-right="isEnableListPaddingRight"
      :is-show-tooltip="isShowTooltip"
      @selected="onSelect"
      @toggleMenu="toggleMenu"
    >
      <AInput
        ref="menuRef"
        v-model="visibleValue"
        :readonly="!filterable"
        :placeholder="placeholder"
        :is-disabled="isDisabled"
        :style-options="inputStyleOptionsComputed"
        @input="onInput"
      />
      <template #menu-item-right="{ slotItem }">
        <slot name="menu-item-right" :slot-item="slotItem" />
      </template>
      <template #menu-additional-item-bottom>
        <slot name="menu-additional-item-bottom" />
      </template>
    </OMenu>
    <template #input-icon-right>
      <AIconArrow :active="isMenuOpen" />
    </template>
    <template #input-icon-right-2>
      <AIcon
        v-if="iconRight"
        :is-original="true"
        height="20"
        :name="`${iconRight}`"
      />
      <AColumnType
        v-if="columnType"
        :column-type="columnType"
      />
      <slot name="input-icon-right-2" />
    </template>
  </AInputWrapper>
</template>

<script>
import {
  ref,
  watch,
  computed,
  defineComponent,
} from 'vue'

import OMenu from '@/shared/components/o-menu'
import AInput from '@/shared/components/a-input'
import AIcon from '@/shared/components/a-icon'
import AColumnType from '@/shared/components/a-column-type'
import AIconArrow from '@/shared/components/a-icon-arrow'
import AInputWrapper from '@/shared/components/a-input-wrapper'

import { useElementSize } from '@vueuse/core'

export default defineComponent({
  name: 'ASelectNew',
  components: {
    OMenu,
    AInput,
    AIcon,
    AColumnType,
    AIconArrow,
    AInputWrapper,
  },
  props: {
    value: {
      type: [String, Number, Boolean],
      default: () => '',
    },
    /**
     * required - if true, the field is required and marked with red asterisk
     */
    required: {
      type: Boolean,
      default: false,
    },
    /**
     * if any message, border will have red color
     * use if you want display error message
     */
    error: {
      type: String,
      default: '',
    },
    /**
     * if true, border will have red border color
     * use if you want change only border color
     */
    hasError: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: () => '',
    },
    iconRight: {
      type: String,
      default: () => '',
    },
    tooltip: {
      type: String,
      default: () => '',
    },
    placeholder: {
      type: String,
      default: () => '',
    },
    filterable: {
      type: Boolean,
      default: () => false,
    },
    /**
     * if true exclude selected value from options list
    */
    removeSelectedValue: {
      type: Boolean,
      default: () => true,
    },
    /**
     * add to item object key:
     * "exclude: true" - to skip this item and don't show him at list
     */
    items: {
      type: Array,
      default: () => [],
    },
    /**
     * Message displayed at bottom of items.
     * For example "Some info"
     */
    additionalMessage: {
      type: String,
      default: '',
    },
    /**
     * if true at list item title will shorter it need for showing type value
     */
    isEnableListPaddingRight: {
      type: Boolean,
      default: () => false,
    },
    /**
     * if true show full item.title on hover
     */
    isShowTooltip: {
      type: Boolean,
      default: () => false,
    },
    isDisabled: {
      type: Boolean,
      default: () => false,
    },
    width: {
      type: [Number, String],
      default: () => null,
    },
    /**
     * You can prop style for example:
     * {
     *   'margin-bottom': `${10}px`,
     *   'border': 'none',
     * }
     */
    inputStyleOptions: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const menuRef = ref(null)
    const { width: inputWidth } = useElementSize(menuRef)
    const styleOptions = { 'max-width': props.width }
    const isMenuOpen = ref(false)
    const isFilterOn = ref(false)
    const isAnyChanges = ref(false)
    const selectedValue = computed({
      get: () => {
        return props.value
      },
      set: (value) => {
        if (!isAnyChanges.value) {
          isAnyChanges.value = true
        }
        emit('input', value)
      },
    })

    function getStartValue() {
      const valueInItems = props.items.find(item => item.value === selectedValue.value)?.title
      const valueOnStart = valueInItems || props.value
      return valueOnStart
    }
    const visibleValue = ref(getStartValue())
    const columnType = computed(() => {
      const itemType = props.items.find(item => item.value === selectedValue.value)?.columnType
      return itemType || ''
    })

    const inputStyleOptionsComputed = computed(() => {
      return {
        ...props.inputStyleOptions,
        'padding-right': columnType.value ? '55px' : '15px',
      }
    })

    const itemsFiltered = computed(() => {
      let result = props.items.filter(item => !item.exclude)
      if (props.filterable && isFilterOn.value) {
        result = result
          .filter((item) => item.title?.toLowerCase().includes(visibleValue.value?.toLowerCase()))
      } else if (props.removeSelectedValue) {
        result = result.filter(item => item.value !== selectedValue.value)
      }
      return result
    })

    const onSelect = (event) => {
      selectedValue.value = event
      visibleValue.value = props.items.find(item => item.value === event)?.title
      isFilterOn.value = false
      emit('selected', event)
    }

    const onInput = () => {
      if (visibleValue.value) {
        isFilterOn.value = true
      } else {
        isFilterOn.value = false
        selectedValue.value = ''
      }
    }

    const toggleMenu = (isOpen) => {
      isMenuOpen.value = isOpen
      const value = props.items.find(item => item.title === visibleValue.value)?.value
      // in no items for example you disconnect dataset, we will use not item.title but item.value
      if (!isMenuOpen.value && !value && selectedValue.value && !props.items.length) {
        visibleValue.value = props.value
      } else if (!isMenuOpen.value && !value && selectedValue.value) {
        visibleValue.value = props.items.find(item => item.value === selectedValue.value)?.title
        isFilterOn.value = false
        emit('selected', selectedValue.value)
      } else if (!isMenuOpen.value && value && !selectedValue.value) {
        visibleValue.value = ''
      }
    }

    const triggerMenu = () => {
      if (menuRef.value.$el) {
        menuRef.value.$el.click()
      }
    }

    watch(selectedValue, () => {
      visibleValue.value = props.items.find(item => item.value === selectedValue.value)?.title
    })

    return {
      menuRef,
      isMenuOpen,
      inputWidth,
      columnType,
      selectedValue,
      visibleValue,
      styleOptions,
      itemsFiltered,
      isAnyChanges,
      inputStyleOptionsComputed,
      onInput,
      onSelect,
      toggleMenu,
      triggerMenu,
    }
  },
})
</script>

<style lang="scss">
@import "a-select-new";
</style>
