<template>
  <td
    :at-a-grid-cell-header="atAttribute"
    class="a-grid-cell-header"
  >
    <div class="grid-cell-header-wrapper">
      <div class="grid-cell-header__group">
        <v-tooltip
          top
          :open-delay="1500"
          :max-width="400"
        >
          <template #activator="{ props }">
            <span
              class="grid-cell-header__title"
              :class="classHeaderTitle"
              v-bind="props"
            >
              {{ title }}
            </span>
          </template>
          <span>{{ title }}</span>
        </v-tooltip>
        <!-- <v-hover v-if="sortable">
          <template v-slot:default="{ isHovering, props }">
            <v-icon
              :icon="sortIcon"
              v-bind="props"
              :color="isHovering || isSortActive ? 'black' : '#757575'"
              @click="handleSort"
              class="grid-cell-header__icon"
            ></v-icon>
          </template>
        </v-hover> -->
      </div>
      <!-- <v-hover v-if="isShowCopyButton">
        <template v-slot:default="{ isHovering, props }">
          <v-icon
            icon="mdi-content-copy"
            v-bind="props"
            :color="isHovering ? 'black' : '#757575'"
            @click="handleCopyTitle()"
          ></v-icon>
        </template>
      </v-hover> -->
    </div>
  </td>
</template>

<script>
import {
  computed,
  ref,
} from 'vue'

import useCopyToClipboard from '@/shared/composables/use-copy-to-clipboard'
import {
  EVENT_SORT_CHANGE,
} from '@/shared/constants/bus-events-constants'

export default {
  name: 'AGridCellHeader',
  props: {
    /**
     * Cell header title
     */
    title: {
      type: String,
      required: true,
    },
    /**
     * Cell header value.
     * 'sortBy' must equal 'value' if you want sort by this column
     */
    value: {
      type: String,
      default: '',
    },
    /**
     * if sortable === true shows arrow for sorting column
     */
    sortable: {
      type: Boolean,
      default: false,
    },
    /**
     * Current 'sortBy' value at table headers.
     * Not this current filter for sorting. It need to comparison
     */
    sortBy: {
      type: String,
      default: '',
    },
    /**
     * Current 'sortBy' value at table headers.
     */
    sortDesc: {
      type: Boolean,
      default: () => null,
    },
    /**
     * Is copy to clipboard button shown
     */
    isShowCopyButton: {
      type: Boolean,
      default: true,
    },
    /**
     * Is uppercase header
     */
    isHeaderUppercase: {
      type: Boolean,
      default: true,
    },
    /**
     * AQA attribute
     */
    atAttribute: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const { copyToClipboard } = useCopyToClipboard()
    const direction = computed({
      get: () => props.sortDesc,
      set: (value) => emit('update:sortDesc', value),
    })

    const isSortActive = computed(() => {
      return props.value === props.sortBy
    })

    const classOptions = computed(() => {
      return {
        'grid-cell-header__icon-sort': !isSortActive.value,
        'grid-cell-header__icon-sort--active': isSortActive.value,
      }
    })
    const classHeaderTitle = computed(() => {
      return {
        'grid-cell-header__title-transform': !props.isHeaderUppercase,
      }
    })

    const sortIcon = computed(() => {
      return direction.value && isSortActive.value ? 'mdi-sort-descending' : 'mdi-sort-ascending'
    })

    const handleCopyTitle = () => {
      if (props.isShowCopyButton) {
        copyToClipboard(props.title)
      }
    }

    function handleSort() {
      isSortActive.value = true
      direction.value = !direction.value
      emit(EVENT_SORT_CHANGE, props.value)
    }

    return {
      isSortActive,
      direction,
      classOptions,
      sortIcon,
      classHeaderTitle,
      handleSort,
      handleCopyTitle,
    }
  },
}
</script>

<style lang="scss">
@import "a-grid-cell-header";
</style>
