const EXTRA_SMALL = 'extra-small'
const SMALL = 'small'
const SMALL_APP = 'small-app'
const MEDIUM = 'medium'
const LARGE = 'large'
const EXTRA_LARGE = 'extra-large'

const SIZES = {
  EXTRA_SMALL,
  SMALL,
  SMALL_APP,
  MEDIUM,
  LARGE,
  EXTRA_LARGE,
}

const DEFAULT_BUTTON_WIDTH = '70'
const DROPDOWN_ITEM_HEIGHT = '31'
const DROPDOWN_MAX_HEIGHT = '220'
const INPUT_MIN_HEIGHT = '24'
const HEADER_HEIGHT = '46'

const BRICK_HEIGHT = 60
const BRICK_WIDTH = 165
const SLOT_RADIUS = 6
const SLOT_MARGIN = 8
const FIRST_SLOT_CENTER_HEIGHT = 11
const SLOTS_CENTER_HEIGHT_DIFF = 16
const GROUP_PADDING_TOP = 30
const GROUP_PADDING_LEFT = 30
const GROUP_MAX_HEADER = 60
const DEFAULT_GRID_SIZE = 15
const MIN_GRID_SIZE = 5
const MAX_GRID_SIZE = 30

const DEFAULT_GRID_FONT_SIZE = 12

export {
  SMALL,
  LARGE,
  SMALL_APP,
  SIZES,
  MEDIUM,
  SLOT_RADIUS,
  SLOT_MARGIN,
  EXTRA_SMALL,
  EXTRA_LARGE,
  BRICK_WIDTH,
  BRICK_HEIGHT,
  HEADER_HEIGHT,
  GROUP_MAX_HEADER,
  DEFAULT_GRID_SIZE,
  MIN_GRID_SIZE,
  MAX_GRID_SIZE,
  INPUT_MIN_HEIGHT,
  GROUP_PADDING_TOP,
  GROUP_PADDING_LEFT,
  DROPDOWN_MAX_HEIGHT,
  DROPDOWN_ITEM_HEIGHT,
  DEFAULT_BUTTON_WIDTH,
  DEFAULT_GRID_FONT_SIZE,
  SLOTS_CENTER_HEIGHT_DIFF,
  FIRST_SLOT_CENTER_HEIGHT,
}
