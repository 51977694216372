<template>
  <div class="o-data-viewer-sidebar-item">
    <div class="data-viewer-sidebar-item__header">
      <ACheckbox
        v-if="!dataItem.isDataAppPreview"
        v-model="dataItem.isChecked"
        :text="dataItem.title"
        data-test="checkbox"
      />
      <ASwitcherTabs
        v-if="dataItem.isChecked && isPreviewData"
        v-model="datasetMode"
        :items="DATASET_MODES_ITEMS"
        :is-disabled="isSwitcherDisabled"
        :tooltip="switcherTooltip"
        data-test="switcher"
        :style="dataItem.isDataAppPreview ? { width: '100%' } : {}"
        @selected="onClickSwitcher"
      />
    </div>
    <div
      v-if="!dataItem.isFullDataset && dataItem.isChecked && isPreviewData"
      class="data-viewer-sidebar-item__sampling"
      data-test="sampling-config"
    >
      <div class="data-viewer-sidebar-item__inputs">
        <AInput
          v-model="dataItem.limit"
          title="Rows"
          tooltip="Number of rows"
          min="1"
          step="1"
          type="number"
          :is-disabled="dataItem.isLoading"
          :validator="toPositiveIntegerValidator"
          data-test="sampling-config-limit"
        />
        <ASelectNew
          v-model="dataItem.limitType"
          title="Load strategy"
          auto-width
          :is-disabled="dataItem.isLoading"
          :items="DATA_LOAD_STRATEGIES_ITEMS"
          data-test="sampling-config-load-strategy"
        />
      </div>
      <AButton
        theme="primary"
        color="red"
        text="Apply"
        :is-disabled="dataItem.isLoading"
        data-test="button-apply"
        @clicked="handleApply"
      />
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, inject, ref } from 'vue'

import AInput from '@/shared/components/a-input'
import AButton from '@/shared/components/a-button'
import ACheckbox from '@/shared/components/a-checkbox'
import ASelectNew from '@/shared/components/a-select-new'
import ASwitcherTabs from '@/shared/components/a-switcher-tabs'

import useValidators from '@/shared/composables/use-validators'

import {
  DATASET_MODES,
  DATASET_MODES_ITEMS,
  DATASET_SOURCE_TYPES,
  DATA_LOAD_STRATEGIES_ITEMS,
  MESSAGES,
  TABS_MAP,
} from '@/shared/constants/data-viewer-constants'
import useDataViewer from '../use-data-viewer'
import { randomString } from '@/shared/utils/randomString'
import { useDataViewerPreparePath } from '../use-data-viewer-prepare-path'

export default defineComponent({
  name: 'ODataViewerSidebarItem',
  components: {
    AInput,
    AButton,
    ACheckbox,
    ASelectNew,
    ASwitcherTabs,
  },
  props: {
    dataItemKey: {
      type: String,
      default: () => '',
    },
  },
  setup(props) {
    const brickConnectors = inject('brickConnectors', ref({}))
    const dataItem = brickConnectors.value[props.dataItemKey]
    console.log('ODataViewerSidebarItem dataItem', dataItem)
    const { toPositiveIntegerValidator } = useValidators()
    const {
      connectorConfigSample,
      connectorConfigFullData,
    } = useDataViewer()
    const { preparePath } = useDataViewerPreparePath()
    const currentTab = inject('currentTab')
    const isPreviewData = computed(() => currentTab.value === TABS_MAP.DATA_PREVIEW)
    const isSwitcherDisabled = computed(() => dataItem.isLoading || !dataItem.persisted_data_set_path)

    const handleApply = () => {
      brickConnectors.value[props.dataItemKey] = {
        ...dataItem,
        reloadId: randomString(),
        isFullDataset: false,
        ...preparePath({
          ...dataItem,
          datasetType: DATASET_SOURCE_TYPES.PERSISTED,
        }),
      }
    }

    const onClickSwitcher = (event) => {
      if (DATASET_MODES.FULL_DATASET.value === event) {
        brickConnectors.value[props.dataItemKey] = connectorConfigFullData(dataItem, true)
      } else {
        brickConnectors.value[props.dataItemKey] = connectorConfigSample(dataItem, true)
      }
    }

    const datasetMode = computed({
      get: () => (
        dataItem.isFullDataset
          ? DATASET_MODES.FULL_DATASET.title
          : DATASET_MODES.SAMPLE.title
      ),
      set: (value) => {
        const isFullDataset = value === DATASET_MODES.SAMPLE.title
        brickConnectors.value[props.dataItemKey] = { ...dataItem, isFullDataset }
      },
    })

    const switcherTooltip = isSwitcherDisabled.value ? MESSAGES.FULL_DATA_ALERT : null

    return {
      dataItem,
      isPreviewData,
      switcherTooltip,
      datasetMode,
      isSwitcherDisabled,
      DATASET_MODES_ITEMS,
      DATA_LOAD_STRATEGIES_ITEMS,
      handleApply,
      onClickSwitcher,
      toPositiveIntegerValidator,
    }
  },
})
</script>

<style lang="scss">
@import "o-data-viewer-sidebar-item";
</style>
