<template>
  <th class="o-grid-preview-data-cell-type">
    <OMenuButtonText
      :items="items"
      :nudge-bottom="20"
      :nudge-left="5"
      :min-width="150"
      :max-width="250"
      :value="dataTypes[column.value].type"
      :sub-item-value="dataTypes[column.value].datetime_format"
      @selected="handleChangeType(column.value, $event)"
    />
  </th>
</template>

<script>
import { computed, defineComponent } from 'vue'

import OMenuButtonText from '@/shared/components/o-menu-button-text'

import { DATA_TYPE_OPTIONS } from '../constants'
import {
  EVENT_TYPE_CHANGE,
} from '@/shared/constants/bus-events-constants'

export default defineComponent({
  name: 'OGridPreviewDataCellType',
  components: {
    OMenuButtonText,
  },
  props: {
    column: {
      type: Object,
      default: () => ({}),
    },
    dataTypes: {
      type: Object,
      default: () => ({}),
    },
    isDisabledTypes: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    function handleChangeType(column, type) {
      /**
       * Emitted when column type changed
       */
      emit(EVENT_TYPE_CHANGE, { column, type })
    }

    const items = computed(() => props.isDisabledTypes ? [] : DATA_TYPE_OPTIONS)

    return {
      items,
      handleChangeType,
    }
  },
})
</script>

<style lang="scss">
@import "o-grid-preview-data-cell-type";
</style>
