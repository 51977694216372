import { randomString } from "@/shared/utils/randomString"
import {
  CONDITIONS,
  DATASET_SOURCE_TYPES,
  DATA_LOAD_STRATEGIES,
  DEFAULT_COUNT_SAMPLE_ROWS,
} from '@/shared/constants/data-viewer-constants'

export const useDataViewerCreateDataItem = () => {
  const createDataItem = ({
    id,
    headers,
    path,
    stats_path = '',
    pipelineId,
    isShowTabs = true,
    title,
    isDataAppPreview = false,
    apiKeyUuid,
    groupId,
  }) => ({
    id,
    key: id,
    headers,
    isOutput: true,
    isShowTabs,
    isDataAppPreview: isDataAppPreview,
    isChecked: true,
    isFullDataset: false,
    limit: DEFAULT_COUNT_SAMPLE_ROWS,
    limitType: DATA_LOAD_STRATEGIES.TOP,
    totalRows: DEFAULT_COUNT_SAMPLE_ROWS,
    path,
    targetPath: path,
    stats_path,
    isLoading: false,
    persisted_data_set_path: '',
    datasetType: DATASET_SOURCE_TYPES.PERSISTED,
    pipelineId,
    reloadId: randomString(),
    filter: null,
    filterProperties: {
      filters: [],
      condition: CONDITIONS.AND.value,
    },
    sort_by: [],
    columnTypes: {},
    sortByColumn: null,
    sortDescColumn: null,
    title,
    apiKeyUuid,
    groupId, // TODO: Remove groupId
  })

  return {
    createDataItem,
  }
}
