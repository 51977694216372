import { ref } from 'vue'

import {
  setItemToLocalStorage,
  getItemFromLocalStorage,
} from '@/shared/services/local-storage-service.js'

function useGridStorage(gridKey) {
  const gridOptions = ref({
    itemsPerPage: 10,
    multiSort: false,
    mustSort: false,
    page: 1,
    sortBy: [],
    sortDesc: [],
    groupBy: [],
    groupDesc: [],
  })

  function saveGridOptionsToStorage() {
    setItemToLocalStorage(gridKey.value, gridOptions.value)
  }

  function init() {
    const options = getItemFromLocalStorage(gridKey.value) || {}
    options.page = 1
    gridOptions.value = {
      ...gridOptions.value,
      ...options,
    }
  }

  init()

  return {
    gridOptions,
    saveGridOptionsToStorage,
  }
}

export default useGridStorage
