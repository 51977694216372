<template>
  <div
    v-if="isShowMessage"
    class="o-message"
    :class="messageClass"
  >
    <div class="o-message__header">
      <div class="o-message__header--left">
        <OMessageIcon :isUserMessage="isUserMessage" :icon-path="aiAnalyst?.iconPath" />
      </div>
      <div class="o-message__header--right">
        <div v-if="message.title" v-html="message.title"></div>
        <div v-if="message.prompt && !message.answer" v-html="message.prompt"></div>
        <OMessageSuggestedQuestions :message="message" @onSetMessage=onSetMessage />
        <OMessageTechDetails v-if="isShowTechDetails" :message="message" />
        <OMessageAnswer :message="message" />
        <OMessageCharts :message="message" />
        <ODataViewer
          v-if="message.tables && message.tables.length"
          :current-tab="currentTab"
          :brick-connectors="brickConnectors"
          :shared-mode="true"
        />
        <OMessageMenu v-if="message.textSummary && !message.isLoading" :message="message" @select=onSelectFeedback />
        <div v-if="message.error" v-html="message.error" />
      </div>
    </div>
    <AModalFeedback
      v-model="isShowModal"
      :message="message"
      @submit="submitFeedback"
    >
    </AModalFeedback>
  </div>
</template>

<script setup>
import {
  ref,
  computed,
  defineEmits,
  defineProps,
  provide,
  onMounted,
} from 'vue';

import AModalFeedback from '../a-modal-feedback';
import OMessageCharts from './o-message-charts.vue';
import OMessageAnswer from './o-message-answer.vue';
import OMessageTechDetails from './o-message-tech-details';
import OMessageSuggestedQuestions from './o-message-suggested-questions.vue';
import OMessageIcon from './o-message-icon.vue';
import OMessageMenu from './o-message-menu.vue';
import ODataViewer from '@/shared/components/o-data-viewer';
import { useDataViewerCreateDataItem } from '@/shared/components/o-data-viewer'
import { TABS_MAP } from '@/shared/constants/data-viewer-constants';

import { feedback } from '@/api/conversation.api';
import { useRoute } from 'vue-router';
import { watch } from 'vue';

const emit = defineEmits(['onSetMessage', 'onSelectFeedback'])
const props = defineProps({
  message: {
    type: Object,
    default: () => ({})
  },
  aiAnalyst: {
    type: Object,
    default: () => null,
  },
})

const route = useRoute()
const isShowModal = ref(false)

const isShowTechDetails = computed(() => route.query.techDetails !== 'false')
const isUserMessage = computed(() => props.message.type === 'user')
const isSystemMessage = computed(() => props.message.type === 'system')
const messageClass = computed(() => ({
  'o-message--user': isUserMessage.value,
  'o-message--system': isSystemMessage.value,
}))
const isShowMessage = computed(() => (
  Boolean(props.message.textSummary) ||
  isShowTechDetails.value ||
  props.message.prompt ||
  props.message.questions ||
  props.message.charts?.length ||
  props.message.tables?.length

))

const onSetMessage = (text) => {
  emit('onSetMessage', text)
}

async function submitFeedback({ liked, comments } = { liked: false, comments: [] }) {
  isShowModal.value = false
  try {
    const { data } = await feedback({
      payload: {
        liked,
        comments,
        message_uuid: props.message.uuid,
      },
      query: {
        apiKeyUuid: route.query.apiKeyUuid,
      },
    })
    emit('onSelectFeedback', data)
  } catch (e) {
    console.error(e)
  } finally {
    isShowModal.value = false
  }
  console.log('Feedback submitted')
}

const onSelectFeedback = (liked = false) => {
  if (liked && props.message.feedback?.liked !== false) {
    submitFeedback({ liked, comments: [] })
  } else if (liked === false) {
    isShowModal.value = true
  }
}

const currentTab = ref(TABS_MAP.DATA_PREVIEW)
const brickConnectors = ref({})

const setupTables = () => {
  const isLocalAnalystBackendMode = process.env.VUE_APP_LOCAL_ANALYST_BACKEND_MODE === 'true'
  if (isLocalAnalystBackendMode) return
  console.log('setupTables', props.message.tables)
  if (!props.message.tables || !props.message.tables.length) return
  const apiKeyUuid = route.query.apiKeyUuid

  const { createDataItem } = useDataViewerCreateDataItem()
  props.message.tables.forEach((table, index) => {
    brickConnectors.value[`table${index}`] = createDataItem({
      id: `table${index}`,
      path: table.path,
      apiKeyUuid,
      isShowTabs: true,
      title: table.title,
      groupId: 147, // TODO: remove hardcoded value
    })
  })
}

watch(() => props.message.tables, () => {
  setupTables()
})

onMounted(() => {
  setupTables()
})

provide('brickConnectors', brickConnectors)
provide('currentTab', currentTab)
</script>

<style lang="scss">
@import "o-message.scss";
</style>
