<template>
  <div
    v-if="message.textTechDetails"
    elevation="1"
    class="o-message-tech-details"
  >
    <div
      v-if="!isOpened"
      class="o-message-tech-details__header-closed"
      @click="handleOpen"
    >
      <div class="tech-details__header-sideline"></div>
      <div class="tech-details__header-closed-central">
        <p>
          Tech details
        </p>
        <AIconMaximize />
      </div>
      <div class="tech-details__header-sideline"></div>
    </div>
    <div
      v-else
      class="tech-details--opened-wrapper"
    >
      <div
        class="tech-details--opened"
      >
        <div
          class="tech-details__header-opened"
          @click="handleOpen"
        >
          <p>
            Tech details
          </p>
          <AIconMinimize
            class="tech-details-minimize"
          />
        </div>
        <v-md-editor v-if="message.textTechDetails" v-model="textTechDetails" mode="preview" height="auto" class="md-editor"></v-md-editor>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from 'vue';
import { useMessageTechDetails } from './use-o-message-tech-details'

import AIconMaximize from '../../a-icon-maximize'
import AIconMinimize from '../../a-icon-minimize'

const props = defineProps({
  message: {
    type: Object,
    required: true
  }
});

const {
  isOpened,
  textTechDetails,
  handleOpen,
} = useMessageTechDetails({ props })
</script>

<style scope lang="scss">
@import "o-message-tech-details";

</style>