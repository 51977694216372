<template>
  <div class="o-data-viewer-sidebar">
    <ODataViewerSidebarItem
      v-for="brickConnector in brickConnectors"
      :key="brickConnector.id"
      :dataItemKey="brickConnector.id"
      data-test="ODataViewerSidebarItem"
    />
  </div>
</template>

<script>
import { inject, ref } from 'vue';
import ODataViewerSidebarItem from '../o-data-viewer-sidebar-item'

export default {
  name: 'ODataViewerSidebar',
  components: {
    ODataViewerSidebarItem,
  },
  setup() {
    const brickConnectors = inject('brickConnectors', ref({}))
    console.log('ODataViewerSidebar brickConnectors', brickConnectors.value)

    return {
      brickConnectors,
    }
  },
}
</script>

<style lang="scss">
@import "o-data-viewer-sidebar";
</style>
