export const useUrl = () => {
  const createUrl = (APIUrl, route, token) => {
    const url = new URL('/api/v1' + route, APIUrl)
    url.searchParams.append('token', token)
    return url
  }

  const openUrlInNewTab = (url) => {
    window.open(url, '_blank')
  }

  return {
    createUrl,
    openUrlInNewTab,
  }
}
