import { randomString } from '@/shared/utils/randomString'
import { retrieveError } from '@/shared/utils/retrieveError'

const state = {
  currentSnackbars: {},
}

const getters = {
  getCurrentSnackbars: state => state.currentSnackbars,
}

const mutations = {
  PUSH_SNACKBAR(state, payload) {
    const {
      key,
      text,
      data,
      isError = false,
      isErrorLite = false,
      isWarning = false,
      isSuccess = false,
      isManuallyExpiring = false,
      isDisplayCloseButton = false,
      isShowLoader = false,
      componentName = null,
      timeToExpire,
      group,
    } = payload
    const newSnackbar = {
      key,
      text,
      data,
      isError,
      isErrorLite,
      isSuccess,
      isWarning,
      isManuallyExpiring,
      isDisplayCloseButton,
      isShowLoader,
      timeToExpire,
      group,
      componentName,
    }
    state.currentSnackbars = {
      [newSnackbar.key]: newSnackbar,
      ...state.currentSnackbars,
    }
    return newSnackbar
  },
  REMOVE_SNACKBAR(state, key) {
    const { [key]: keyValue, ...updatedSnackbars } = state.currentSnackbars
    state.currentSnackbars = updatedSnackbars
  },
  REMOVE_SNACKBAR_GROUP(state, groupKey) {
    state.currentSnackbars = Object.keys(state.currentSnackbars)
      .reduce((acc, key) => {
        if (state.currentSnackbars[key]?.group === groupKey) {
          return acc
        }
        return {
          ...acc,
          [key]: state.currentSnackbars[key],
        }
      }, {})
  },
  REMOVE_SNACKBARS_BEFORE_ROUTE(state) {
    const componentNameExceptions = [
      'o-snackbar-download-asset',
    ]
    Object.keys(state.currentSnackbars).forEach(key => {
      const includesExeption = componentNameExceptions.includes(state.currentSnackbars[key].componentName)
      if (!includesExeption) {
        const { [key]: keyValue, ...updatedSnackbars } = state.currentSnackbars
        state.currentSnackbars = updatedSnackbars
      }
    })
  },
  REMOVE_ALL_SNACKBARS_EXCEPT_ERRORS(state) {
    state.currentSnackbars = Object.keys(state.currentSnackbars)
      .reduce((acc, key) => {
        if (state.currentSnackbars[key]?.isError) {
          return {
            ...acc,
            [key]: state.currentSnackbars[key],
          }
        }
        return acc
      }, {})
  },
}

const actions = {
  pushCustomSnackbar({ commit }, config) {
    try {
      commit('PUSH_SNACKBAR', { key: randomString(), ...config })
    } catch (err) {
      console.error(err)
    }
  },
  pushDefaultSnackbar({ commit }, payload) {
    const text = typeof payload === 'string' ? payload : payload.text
    const {
      isManuallyExpiring,
      isDisplayCloseButton,
      group = null,
    } = payload
    try {
      const config = {
        key: randomString(),
        text,
        group,
        isManuallyExpiring,
        isDisplayCloseButton,
      }
      commit('PUSH_SNACKBAR', config)
    } catch (err) {
      console.error(err)
    }
  },
  pushErrorSnackbar({ commit }, payload) {
    const error = typeof payload === 'string' ? payload : payload?.error || payload
    const {
      isManuallyExpiring = payload?.isManuallyExpiring ?? false,
      isDisplayCloseButton = false,
      group = null,
    } = payload
    try {
      const config = {
        key: randomString(),
        text: retrieveError(error),
        isError: true,
        group,
        isManuallyExpiring,
        isDisplayCloseButton,
        isShowLoader: true,
      }
      commit('REMOVE_ALL_SNACKBARS_EXCEPT_ERRORS')
      commit('PUSH_SNACKBAR', config)
    } catch (err) {
      console.error(err)
    }
  },
  pushErrorLiteSnackbar({ commit }, payload) {
    const error = typeof payload === 'string' ? payload : payload?.error || payload
    const {
      isManuallyExpiring = false,
      isDisplayCloseButton = true,
      group = null,
    } = payload
    try {
      const config = {
        key: randomString(),
        text: retrieveError(error),
        isErrorLite: true,
        group,
        isManuallyExpiring,
        isDisplayCloseButton,
        isShowLoader: true,
      }
      commit('REMOVE_ALL_SNACKBARS_EXCEPT_ERRORS')
      commit('PUSH_SNACKBAR', config)
    } catch (err) {
      console.error(err)
    }
  },
  pushWarningSnackbar({ commit }, payload) {
    const error = typeof payload === 'string' ? payload : payload?.error || payload
    const {
      isManuallyExpiring = false,
      isDisplayCloseButton = true,
      group = null,
    } = payload
    try {
      const config = {
        key: randomString(),
        text: retrieveError(error),
        isWarning: true,
        group,
        isManuallyExpiring,
        isDisplayCloseButton,
        isShowLoader: true,
      }
      commit('REMOVE_ALL_SNACKBARS_EXCEPT_ERRORS')
      commit('PUSH_SNACKBAR', config)
    } catch (err) {
      console.error(err)
    }
  },
  pushSuccessSnackbar({ commit }, payload) {
    const text = typeof payload === 'string' ? payload : payload.text
    const {
      isManuallyExpiring = false,
      isDisplayCloseButton = false,
      group = null,
    } = payload
    try {
      const config = {
        key: randomString(),
        text,
        isSuccess: true,
        group,
        isManuallyExpiring,
        isDisplayCloseButton,
      }
      commit('PUSH_SNACKBAR', config)
    } catch (err) {
      console.error(err)
    }
  },
  removeSnackbarGroup({ commit }, groupKey) {
    commit('REMOVE_SNACKBAR_GROUP', groupKey)
  },
  removeSnackbar({ commit }, key) {
    try {
      commit('REMOVE_SNACKBAR', key)
    } catch (err) {
      console.error(err)
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
