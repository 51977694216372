<template>
  <div class="o-data-viewer-tables-summary">
    <ODataViewerTable
      v-for="dataItem in checkedDataItems"
      :key="dataItem.key + 'summary'"
      :grid="TABS_MAP.SUMMARY"
      :data-item="dataItem"
    />
  </div>
</template>

<script>
export default {
  name: 'ODataViewerTablesSummary',
}
</script>

<script setup>
import { defineProps } from 'vue'
import ODataViewerTable from '../o-data-viewer-table'

import { TABS_MAP } from '@/shared/constants/data-viewer-constants'

defineProps({
  checkedDataItems: {
    type: Array,
    default: () => [],
  },
})
</script>

<style lang="scss">
@import "o-data-viewer-tables-summary";
</style>
