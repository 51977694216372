<template>
  <div
    class="a-input-wrapper"
    :class="classInputWrapper"
  >
    <div
      v-if="title"
      class="input-wrapper__header"
    >
      <div class="input-wrapper__header-label">
        {{ title }}
      </div>
      <span
        v-if="required"
        class="brick-feature__header-title--required"
      >
        *
      </span>
      <ATooltip
        v-if="tooltip"
        :content="tooltip"
      >
        <AIcon
          class="input-wrapper__header-tooltip"
          name="icons/tooltip"
          is-original
          height="16"
          width="16"
        />
      </ATooltip>
    </div>
    <div class="input-wrapper__slot" @click="() => $emit('click')">
      <slot />
      <div
        v-if="$slots['input-icon-right']"
        class="input-wrapper__slot-icon-right"
      >
        <slot name="input-icon-right" />
      </div>
      <div
        v-if="$slots['input-icon-right-2']"
        class="input-wrapper__slot-icon-right-2"
      >
        <slot name="input-icon-right-2" />
      </div>
    </div>
    <div>
      <transition name="fadeIn">
        <div
          v-if="error"
          class="input-wrapper__error-container"
        >
          <div v-if="error" class="input-wrapper__error">
            {{ error }}
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue'

import AIcon from '@/shared/components/a-icon'
import ATooltip from '@/shared/components/a-tooltip'

export default defineComponent({
  name: 'AInputWrapper',
  components: {
    AIcon,
    ATooltip,
  },
  props: {
    title: {
      type: String,
      default: () => '',
    },
    /**
     * if true, border will have red border color
     */
    error: {
      type: String,
      default: null,
    },
    /**
     * if true, border will have red border color
     * use if you want change only border color
     */
    hasError: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: () => '',
    },
    /**
     * required - if true, the field is required and marked with red asterisk
     */
    required: {
      type: Boolean,
      default: false,
    },
    /**
     * is input has any changes
     */
    isAnyChanges: {
      type: Boolean,
      default: false,
    },
    /**
     * is input has any changes
     */
    errorAnimationAtStart: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const classInputWrapper = computed(() => ({
      'input-wrapper--error-animation': props.errorAnimationAtStart ? Boolean(props.error || props.hasError) : Boolean(props.error && props.isAnyChanges),
      'input-wrapper--error-status': Boolean(props.error || props.hasError),
    }))

    return {
      classInputWrapper,
    }
  },
})
</script>

<style lang="scss">
@import "a-input-wrapper";
</style>
