export const taskStatuses = {
  SUCCESS: 'SUCCESS',
  SCHEDULED: 'SCHEDULED',
  CALCULATING: 'CALCULATING',
  PRELOADING: 'PRELOADING',
  PENDING: 'PENDING',
  STARTED: 'STARTED',
  FAILURE: 'FAILURE',
  SKIPPED: 'SKIPPED',
  NOT_STARTED: 'NOT STARTED',
}

export const pipelinePendingStatuses = [
  taskStatuses.SCHEDULED,
  taskStatuses.CALCULATING,
  taskStatuses.PRELOADING,
  taskStatuses.PENDING,
  taskStatuses.STARTED,
  taskStatuses.NOT_STARTED,
]
