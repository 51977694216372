import { TYPES } from './data-types-constants'

export const DEFAULT_COUNT_SAMPLE_ROWS = 1000
export const DEFAULT_COUNT_CELL_SYMBOLS = 1000

export const TABS_MAP = {
  DATA_PREVIEW: 'Data Preview',
  SUMMARY: 'Summary',
}

export const DATASET_SOURCE_TYPES = {
  STATS: 'stats',
  SAMPLE: 'sample',
  PERSISTED: 'persisted',
}

export const DATA_LOAD_STRATEGIES = {
  TOP: 'top',
  BOTTOM: 'bottom',
  EDGES: 'edges',
}

export const DATA_LOAD_STRATEGIES_ITEMS = [
  { title: DATA_LOAD_STRATEGIES.TOP, value: DATA_LOAD_STRATEGIES.TOP },
  { title: DATA_LOAD_STRATEGIES.BOTTOM, value: DATA_LOAD_STRATEGIES.BOTTOM },
  { title: DATA_LOAD_STRATEGIES.EDGES, value: DATA_LOAD_STRATEGIES.EDGES },
]

export const DATASET_MODES = {
  SAMPLE: { value: 'Sample', title: 'Sample' },
  FULL_DATASET: { value: 'Full Data', title: 'Full Data' },
}

export const DATASET_MODES_ITEMS = [
  DATASET_MODES.SAMPLE,
  DATASET_MODES.FULL_DATASET,
]

export const MESSAGES = {
  FULL_DATA_ALERT: 'No data available',
  SUMMARY_ALERT: 'Run pipeline to get summary insights',
}

export const CONDITIONS = {
  OR: { title: 'Or', value: '|' },
  AND: { title: 'And', value: '&' },
}

export const CONDITION_ITEMS = [
  CONDITIONS.OR,
  CONDITIONS.AND,
]

const TYPES_NUMBERS_DATETIME = [TYPES.FLOAT.value, TYPES.INTEGER.value, TYPES.DATETIME.value]
const TYPES_ALL = [...TYPES_NUMBERS_DATETIME, TYPES.STRING.value, TYPES.BOOLEAN.value, TYPES.CATEGORY.value]

export const OPERATORS = {
  BETWEEN: { value: 'between', title: 'between', types: TYPES_NUMBERS_DATETIME },
  ISNULL: { value: 'isnull', title: 'is null', types: TYPES_ALL },
  ISNOTNULL: { value: 'isnotnull', title: 'is not null', types: TYPES_ALL },
}
export const OPERATOR_ITEMS = [
  { value: '>=', title: '>=', types: TYPES_NUMBERS_DATETIME }, // - numbers, dates
  { value: '<=', title: '<=', types: TYPES_NUMBERS_DATETIME }, // - numbers, dates
  { value: '<', title: '<', types: TYPES_NUMBERS_DATETIME }, // - numbers, dates
  { value: '>', title: '>', types: TYPES_NUMBERS_DATETIME }, // - numbers, dates
  { value: '!=', title: '!=', types: TYPES_ALL }, // - numbers, dates, string, boolean, category
  { value: '=', title: '=', types: TYPES_ALL }, // - numbers, dates, string, boolean, category
  OPERATORS.BETWEEN, // - numbers, dates
  { value: 'not contains', title: 'not contains', types: [TYPES.STRING.value, TYPES.CATEGORY.value] }, // - string, category
  { value: 'contains', title: 'contains', types: [TYPES.STRING.value, TYPES.CATEGORY.value] }, // - string, category
  { value: 'startswith', title: 'startswith', types: [TYPES.STRING.value, TYPES.CATEGORY.value] }, // - string, category
  { value: 'endswith', title: 'endswith', types: [TYPES.STRING.value, TYPES.CATEGORY.value] }, // - string, category
  OPERATORS.ISNULL, // - all
  OPERATORS.ISNOTNULL, // - all
]

export const DISABLING_VALUE_INPUT_OPERATORS = [
  OPERATORS.ISNULL.value,
  OPERATORS.ISNOTNULL.value,
]
export const BETWEEN_OPERATORS = [
  OPERATORS.BETWEEN.value,
]

export const BOOLEAN_VALUE_ITEMS = [
  { value: true, title: 'true' },
  { value: false, title: 'false' },
]

