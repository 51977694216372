import {
  TYPES,
} from '@/shared/constants/data-types-constants'

export const DATA_TYPE_OPTIONS = [
  TYPES.INTEGER,
  TYPES.FLOAT,
  TYPES.STRING,
  TYPES.BOOLEAN,
  TYPES.CATEGORY,
  TYPES.DATETIME,
]
