export const BLACK = 'black'
export const GREY = 'grey'
export const GREY_248 = 'grey-248'
export const RED = 'red'
export const BLUE = 'blue'
export const GREEN = 'green'
export const ORANGE = 'orange'

export const BUTTON_COLORS = {
  BLACK,
  GREY,
  GREY_248,
  RED,
  BLUE,
}

export const DOT_STATUS_COLORS = {
  RED,
  GREEN,
  ORANGE,
  GREY,
}
