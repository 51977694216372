import { ref } from 'vue'
import { taskStatuses } from '@/shared/constants/task-statuses-constants.js'
import { getTaskStatus } from '../api/task-manager.api'

const useFetchHeavyTask = () => {
  const currentTaskId = ref('')
  const apiKey = ref('')
  const finalStatus = ref('')
  const finalMessage = ref([])
  const isLoading = ref(false)

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  async function pollStatus() {
    const { data } = await getTaskStatus({ taskId: currentTaskId.value, apiKeyUuid: apiKey.value })
    if (data.status === taskStatuses.SUCCESS || data.status === taskStatuses.FAILURE) {
      finalStatus.value = data.status
      finalMessage.value = data.message
      return
    }
    await sleep(1000)
    await pollStatus()
  }

  const fetchHeavyTask = async (requestCallback, params) => {
    try {
      isLoading.value = true
      const { apiKeyUuid } = params
      const { data: { id, deploymentTaskUuid, taskId } } = await requestCallback(params)
      currentTaskId.value = id || deploymentTaskUuid || taskId
      apiKey.value = apiKeyUuid
      await pollStatus()
      isLoading.value = false
      return { status: finalStatus.value, message: finalMessage.value }
    } catch (error) {
      isLoading.value = false
      return { status: taskStatuses.FAILURE, message: error.response?.data.message || '' }
    }
  }

  return {
    sleep,
    fetchHeavyTask,
    isLoading,
  }
}

export default useFetchHeavyTask
