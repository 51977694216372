import { createApp } from 'vue'

import '@/assets/scss/main.scss'

// icons
import { VueSvgIconPlugin } from '@yzfe/vue-svgicon'
import '@yzfe/svgicon/lib/svgicon.css'

// Vuetify
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import 'vuetify/styles'
import '@mdi/font/css/materialdesignicons.css'

import VueMarkdownEditor from '@kangc/v-md-editor';
import '@kangc/v-md-editor/lib/style/base-editor.css';
import githubTheme from '@kangc/v-md-editor/lib/theme/github.js';
import '@kangc/v-md-editor/lib/theme/style/github.css';
import hljs from 'highlight.js';

import App from './App.vue'
import { store } from './store'
import router from './router'

VueMarkdownEditor.use(githubTheme, {
  Hljs: hljs,
  extend() {
    // md is a markdown-it instance, you can modify the configuration here, and use plugin for syntax expansion
    // md.set(option).use(plugin);
  },
});

const vuetify = createVuetify({
  components,
  directives,
})

const app = createApp(App)
  .use(store)
  .use(router)
  .use(vuetify)
  .use(VueSvgIconPlugin, {
    tagName: 'vue-svg-icon',
  })
  .use(VueMarkdownEditor)

app.mount('#app')