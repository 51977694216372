<template>
  <ATooltip
    :content="tooltip"
    :delay-on-mouse-over="1000"
  >
    <div
      class="a-switcher-tabs"
      :class="switcherClassOptions"
    >
      <div
        v-for="item in items"
        :key="item.value"
        class="switcher-tab"
        :class="classSwitcherTab(item.value)"
        @click="onClick(item)"
      >
        {{ item.title }}
      </div>
    </div>
  </ATooltip>
</template>

<script>
import {
  defineComponent,
  computed,
} from 'vue'

import ATooltip from '@/shared/components/a-tooltip'

export default defineComponent({
  name: 'ASwitcherTabs',
  components: {
    ATooltip,
  },
  props: {
    modelValue: {
      type: String,
      default: () => null,
    },
    items: {
      type: Array,
      default: () => [],
    },
    isDisabled: {
      type: Boolean,
      default: () => false,
    },
    tooltip: {
      type: String,
      default: () => '',
    },
    theme: {
      type: String,
      default: () => 'default',
      validator: value => ['default', 'app'].includes(value),
    },
  },
  emits: [
    'selected',
    'input',
  ],
  setup(props, { emit }) {
    const selectedValue = computed({
      get: () => (props.modelValue),
      set: (value) => {
        emit('input', value)
        emit('selected', value)
      },
    })
    const switcherClassOptions = computed(() => {
      return {
        'a-switcher-tabs--app-theme': props.theme === 'app',
      }
    })
    const classSwitcherTab = (item) => ({
      'switcher-tab__selected': item === selectedValue.value,
      'switcher-tab__disabled': props.isDisabled,
    })

    function onClick(item) {
      if (props.isDisabled) return

      selectedValue.value = item.value
      emit('input', item.value)
      emit('selected', item.value)
    }
    return {
      switcherClassOptions,
      onClick,
      classSwitcherTab,
    }
  },
})
</script>

<style lang="scss">
@import "a-switcher-tabs";
</style>
