<template>
  <div class="o-header">
    <div class="o-header__left">
      <div class="o-header__tabs">
        <OHeaderTab
          v-for="item in clientTabs[CLIENT_NAME]"
          :tab="item"
          :key="item.title"
        />
      </div>
    </div>
    <div class="o-header__right" v-if="SHOW_SWITCH">
      <v-switch
        v-model="useCache"
        @update:modelValue="onUseCacheChange"
        label="Use cache"
      ></v-switch>
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';

import OHeaderTab from './o-header-tab'

import {
  CLIENT_NAME,
  CLIENT_NAMES,
  SHOW_SWITCH,
  SHOW_DATA_TAB,
} from '../../constants'
import { ref } from 'vue';

const TABS = {
  CHAT: { title: 'Chat', pathName: 'chat' },
  DATA: { title: 'Data', pathName: 'data' },
}

const clientDatricsTabs = [
    TABS.CHAT,
]
const clientCfoTabs = [
  TABS.CHAT,
]
const clientCfoTbiTabs = [
  TABS.CHAT,
]
if (SHOW_DATA_TAB) {
  clientCfoTbiTabs.push(TABS.DATA)
  clientCfoTabs.push(TABS.DATA)
}
const clientSigmaTabs = [
  TABS.CHAT,
  TABS.DATA
]

const clientTabs = {
  [CLIENT_NAMES.DATRICS]: clientDatricsTabs,
  [CLIENT_NAMES.CFO]: clientCfoTabs,
  [CLIENT_NAMES.CFO_TBI]: clientCfoTbiTabs,
  [CLIENT_NAMES.SIGMA]: clientSigmaTabs,
}

const store = useStore()

const useCache = ref(store.state.config.useCache)

const onUseCacheChange = (value) => {
  console.log('onUseCacheChange', value)
  store.dispatch('config/setUseCache', value)
}


</script>

<style lang="scss">
@import "o-header.scss";
</style>