const DIRECTION = {
  UP: 'up',
  RIGHT: 'right',
  DOWN: 'down',
  LEFT: 'left',
}

const ICON_DIRECTIONS_LIST = [DIRECTION.UP, DIRECTION.RIGHT, DIRECTION.DOWN, DIRECTION.LEFT]
const DRAWER_PLACEMENTS_LIST = [DIRECTION.RIGHT, DIRECTION.LEFT]

const TOOLTIP_DIRECTIONS = {
  TOP: 'top',
  TOP_START: 'top-start',
  TOP_END: 'top-end',
  BOTTOM: 'bottom',
  BOTTOM_START: 'bottom-start',
  BOTTOM_END: 'bottom-end',
  LEFT: 'left',
  LEFT_START: 'left-start',
  LEFT_END: 'left-end',
  RIGHT: 'right',
  RIGHT_START: 'right-start',
  RIGHT_END: 'right-end',
}

export {
  DIRECTION,
  TOOLTIP_DIRECTIONS,
  ICON_DIRECTIONS_LIST,
  DRAWER_PLACEMENTS_LIST,
}
