import serverErrorsHandler from "@/shared/utils/serverErrorsHandler"
import { useStore } from "vuex"

export const useErrorHandler = () => {
  const store = useStore()
  // TODO: Remove store usage
  // const pushErrorSnackbar = store.alerts.pushErrorSnackbar

  const errorHandlerAlert = (error) => {
    // serverErrorsHandler(error, pushErrorSnackbar)
  }
  const errorHandlerAlertManualExpired = (error) => {
    // serverErrorsHandler(error, pushErrorSnackbar, null, { isManuallyExpiring: true })
  }

  return {
    errorHandlerAlert,
    errorHandlerAlertManualExpired,
  }
}

export default useErrorHandler
