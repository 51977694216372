import axios from 'axios'
// import Cookies from 'js-cookie'

const isLocalAnalystBackendMode = process.env.VUE_APP_LOCAL_ANALYST_BACKEND_MODE === 'true'
const apiBaseUrl = process.env.VUE_APP_BACKEND_API_BASE_URL
export const API_BASE_URL = isLocalAnalystBackendMode ? apiBaseUrl : apiBaseUrl + `/chat-explorer-v2`

const apiClient = axios.create({
  baseURL: API_BASE_URL,
})

// const getAuthToken = () => Cookies.get('user-token')
// const authInterceptor = (config) => {
//   config.headers['authorization'] = `Bearer ${getAuthToken()}`
//   return config
// }
// apiClient.interceptors.request.use(authInterceptor)

export default apiClient
