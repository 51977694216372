import { randomString } from '@/shared/utils/randomString'
// import {
//   useCurrentGroup,
//   useCurrentProjectPipeline,
// } from '@/store/use'
// import { STORAGE_BUCKET } from '@/constants'
// TODO: Remove store usage
// TODO: Remove StorageBucket usage

export const useTransformDataset = ({ props }) => {
  // const { currentGroupId } = useCurrentGroup()
  // const {
  //   currentProjectPipelineBasePath,
  // } = useCurrentProjectPipeline()
  // TODO: Remove store usage

  function getTempCsvPath() {
    const tempPath = `temp/${randomString()}.csv`
    return tempPath
  }

  const prepareDataTransformDataset = ({
    sourcePath,
    sourceType,
    targetPath,
    targetType,
  }) => {
    const configuration = {
      datasource: {
        dataset_type: sourceType,
        dataset_config: {
          path: sourcePath,
        },
      },
      datatarget: {
        dataset_type: targetType,
        dataset_config: {
          path: targetPath,
        },
      },
    }

    return {
      configuration,
      groupId: props.groupId,
      task_type: 'transform-data',
    }
  }

  const prepareDataTransformPklToCsv = ({ path, persistedDataSetPath }) => {
    const basePath = props.projectPipelineBasePath
    const filename = path.split('/').pop()
    const fullAssetPath = `${filename}`.replace('.pkl', '.csv')

    return {
      groupId: props.groupId,
      persisted_data_set_path: persistedDataSetPath,
      data_format: 'csv',
      // bucket: STORAGE_BUCKET,
      base_path: basePath,
      path: fullAssetPath,
    }
  }

  return {
    getTempCsvPath,
    prepareDataTransformPklToCsv,
    prepareDataTransformDataset,
  }
}
