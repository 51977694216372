export const ARGS = {
  // general
  TITLE: 'title',
  GREETING: 'greeting',
  PUBLIC_NAME: 'public_name',

  // configuration
  // Instructions
  LEGEND: 'legend',
  AGENT: 'agent',

  TONE: 'tone',
  END_USERS_ROLE: 'end_users_role',
  LIMITATIONS: 'limitations',
  RULES: 'rules',

  CHAT_SETTING: 'chat_setting',
  IS_CONSISTENCY_CHECK: 'is_consistency_check',
  //
  IS_USE_DATA: 'is_use_data',
  IS_SUMMARY_ON: 'is_summary_on',
  IS_SHOW_ASSETS: 'is_show_assets',
  IS_SPEEDUP: 'is_speedup',
  IS_CONFIRM_QUESTION: 'is_confirm_question',
  IS_SHOW_TECH_DETAILS: 'is_show_tech_details',
  HISTORY_SIZE: 'history_size',
  LANGSMITH: 'langsmith',
  IS_SUMMARY_SHOW_TABLES: 'is_summary_show_tables',
  IS_SUMMARY_OUTPUT_DATA: 'is_summary_output_data',
  USE_SUMMARY_IN_QUESTION: 'use_summary_in_question',
  //
  OPERATIONS: 'operations',
  INSTRUCTIONS: 'instructions',
  PIPELINE_API_DESCRIPTION: 'pipeline_api_description',
  INITIAL_QUESTIONS: 'init_questions',
  //
  DEV_KEYS: 'dev_keys',
  // Dataset
  DESCRIPTION: 'description',
  // Datasets
  DATASETS_DESCRIPTION: 'datasets_description',
  AUTO_DESCRIBE: 'auto_describe',
  IS_NEED_UPDATE_DATASETS_DESCRIPTION: 'is_need_update_datasets_description',
  PROJECT_ASSETS: 'projectAssets',
  DATASETS: 'dataSets',
  API_KEY: 'apiKey',
}
