<template>
  <div class="o-data-viewer">
    <!-- <ODataViewerSidebar
      v-if="showSidebar"
      data-test="ODataViewerSidebar"
    /> -->
    <KeepAlive>
      <component
        v-if="checkedDataItems.length"
        :is="componentTable"
        :checked-data-items="checkedDataItems"
        data-test="ODataViewerTablesData"
      />
      <div
        v-else
        data-test="no-data-selected"
        class="data-viewer__no-data-selected"
      >
        <div>No data selected</div>
        <div>Please choose some data in the left settings</div>
      </div>
    </KeepAlive>
  </div>
</template>

<script>
import { computed, inject, ref } from 'vue'

import ODataViewerSidebar from './o-data-viewer-sidebar'
import ODataViewerTablesData from './o-data-viewer-tables-data'
import ODataViewerTablesSummary from './o-data-viewer-tables-summary'

import {
  TABS_MAP,
} from '@/shared/constants/data-viewer-constants'

export default {
  name: 'ODataViewer',
  components: {
    ODataViewerSidebar,
    ODataViewerTablesData,
    ODataViewerTablesSummary,
  },
  props: {
    currentTab: {
      type: String,
      required: true,
    },
    showSidebar: {
      type: Boolean,
      default: () => true,
    },
  },
  setup(props) {
    const brickConnectors = inject('brickConnectors', ref({}))
    const componentTable = computed(() => {
      return props.currentTab === TABS_MAP.DATA_PREVIEW
        ? ODataViewerTablesData.name
        : ODataViewerTablesSummary.name
    })

    const checkedDataItems = computed(() => {
      return Object.values(brickConnectors.value)
        .filter(item => item.isChecked)
    })

    console.log('checkedDataItems', checkedDataItems.value)

    return {
      componentTable,
      checkedDataItems,
    }
  },
}
</script>

<style lang="scss">
@import "o-data-viewer";
</style>
