export const retrieveError = (error) => {
  if (typeof error === 'string') {
    return error
  }
  if (typeof error === 'object' && error[0]?.message) {
    return error[0].message
  }
  if (typeof error === 'object' && (error.message || error?.response?.data?.error)) {
    return error.message
  }
  if (typeof error === 'object' && (error?.error)) {
    return error.error
  }
  return String(error)
}
