// https://codepen.io/crwilson311/pen/Bajbdwd

export const useGridResize = () => {
  function createDiv(height) {
    var div = document.createElement('div')
    div.style.top = 0
    div.style.right = 0
    div.style.width = '5px'
    div.style.position = 'absolute'
    div.style.cursor = 'col-resize'
    div.style.userSelect = 'none'
    div.style.height = height + 'px'
    return div
  }

  function getStyleVal(elm, css) {
    return (window.getComputedStyle(elm, null).getPropertyValue(css))
  }

  function paddingDiff(col) {
    if (getStyleVal(col, 'box-sizing') === 'border-box') {
      return 0
    }

    const padLeft = getStyleVal(col, 'padding-left')
    const padRight = getStyleVal(col, 'padding-right')
    return (parseInt(padLeft) + parseInt(padRight))
  }

  function setListeners(div) {
    let pageX
    let curCol
    let nxtCol
    let curColWidth
    let nxtColWidth

    div.addEventListener('mousedown', (e) => {
      curCol = e.target.parentElement
      nxtCol = curCol.nextElementSibling
      pageX = e.pageX

      const padding = paddingDiff(curCol)

      curColWidth = curCol.offsetWidth - padding

      if (nxtCol) {
        nxtColWidth = nxtCol.offsetWidth - padding
      }
    })

    div.addEventListener('mouseover', (e) => {
      e.target.style.borderRight = '2px solid #0000ff'
    })

    div.addEventListener('mouseout', (e) => {
      e.target.style.borderRight = ''
    })

    document.addEventListener('mousemove', (e) => {
      if (curCol) {
        const diffX = e.pageX - pageX

        if (nxtCol) {
          nxtCol.style.width = (nxtColWidth - (diffX)) + 'px'
        }
        curCol.style.width = (curColWidth + diffX) + 'px'
      }
    })

    document.addEventListener('mouseup', () => {
      curCol = undefined
      nxtCol = undefined
      pageX = undefined
      nxtColWidth = undefined
      curColWidth = undefined
    })
  }

  function resizableGrid(table) {
    const row = table.getElementsByTagName('tr')[0]
    const cols = row ? row.children : undefined
    if (!cols) return

    table.style.overflow = 'hidden'

    const tableHeight = table.offsetHeight

    for (let i = 0; i < cols.length; i++) {
      const div = createDiv(tableHeight)
      cols[i].appendChild(div)
      cols[i].style.position = 'relative'
      setListeners(div)
    }
  }

  const tables = document.getElementsByTagName('table')
  for (let i = 0; i < tables.length; i++) {
    resizableGrid(tables[i])
  }
}
