import { useTime } from '@/shared/composables/use-time'
import backendApiClient, { API_BASE_URL } from './backend-api-client'
import { OUTPUT_PREVIEW_LOAD_TIMEOUT_MSEC } from '@/shared/constants/api-request-constants'

const ENDPOINTS = {
  RUN_PIPELINE: ({ pipelineId }) => `/task-manager/pipeline/run?pipelineId=${pipelineId}`,
  CANCEL_TASK: taskId => `/task-manager/cancel?taskId=${taskId}`,
  GET_TASK_STATUS: ({ taskId, apiKeyUuid }) => `/task-manager/status?taskId=${taskId}&apiKeyUuid=${apiKeyUuid}`,
  LIGHT_RUN_PIPELINE: ({ pipelineId }) => `/task-manager/pipeline/light-run?pipelineId=${pipelineId}`,
  GET_PIPELINE_INTERIMS: ({ pipelineId, basePath, apiKeyUuid }) => `/task-manager/pipeline/interims?pipelineId=${pipelineId}&basePath=${basePath}&apiKeyUuid=${apiKeyUuid}`,
  GET_PIPELINE_METAFILE: ({
    pipelineId, pathToLogFile, deploymentTaskUuid, scope,
  }) => `/task-manager/pipeline/metafile?pipelineId=${pipelineId}&pathToLogFile=${pathToLogFile}&deploymentTaskUuid=${deploymentTaskUuid}&scope=${scope}`,
  DROP_PIPELINE_CACHE: ({ pipelineId }) => `task-manager/pipeline/drop-cache?pipelineId=${pipelineId}`,
  RUN_MODEL_PREDICT: ({
    bucket, version, base_path, path, explanation, groupId,
  }) => `/task-manager/model/predict?bucket=${bucket}&version=${version}&base_path=${base_path}&path=${path}&get_explanation=${explanation}&groupId=${groupId}`,
  RESTART_WORKER: `/task-manager/restart-worker`,
  TASK_GENERATE_AND_VALIDATE_SOURCE_PATHS: '/task-manager/dataset/generate-and-validate-source-paths',
  VALIDATE_CUSTOM_CODE: 'task-manager/validate-custom-code',
  CUSTOM_BRICK_INSTALL_PACKAGES: '/task-manager/custom-brick/test-install',
  CUSTOM_BRICK_DELETE_PACKAGE: '/task-manager/custom-brick/delete-package',
  OUTPUT_PREVIEW_LOAD: '/task-manager/output-preview/load',
  OUTPUT_PREVIEW_PAGE: '/task-manager/output-preview/page',
  OUTPUT_PREVIEW_PAGE_CELL: '/task-manager/output-preview/page-cell',
  TRANSFORM_DATASET: '/task-manager/transform-dataset',
}

export const runPipeline = (args, payload) => {
  return backendApiClient.post(ENDPOINTS.RUN_PIPELINE(args), payload)
}

export const lightRunPipeline = (args, payload) => (
  backendApiClient.post(ENDPOINTS.LIGHT_RUN_PIPELINE(args), payload)
)
export const cancelTask = args => (
  backendApiClient.delete(ENDPOINTS.CANCEL_TASK(args))
)
export const getTaskStatus = args => (
  backendApiClient.get(ENDPOINTS.GET_TASK_STATUS(args))
)
export const getPipelineInterims = (args) => (
  backendApiClient.get(ENDPOINTS.GET_PIPELINE_INTERIMS(args))
)
export const getPipelineMetafile = (args) => (
  backendApiClient.get(ENDPOINTS.GET_PIPELINE_METAFILE(args))
)
export const runModelPredict = ({ payload, ...args }) => (
  backendApiClient.post(ENDPOINTS.RUN_MODEL_PREDICT(args), payload)
)
export const restartWorkers = () => (
  backendApiClient.post(ENDPOINTS.RESTART_WORKER)
)
export const dropPipelineCache = (args) => (
  backendApiClient.post(ENDPOINTS.DROP_PIPELINE_CACHE(args))
)
export const getTaskGenerateAndValidateSourcePaths = (payload) => (
  backendApiClient.post(ENDPOINTS.TASK_GENERATE_AND_VALIDATE_SOURCE_PATHS, payload)
)
export const validateCodeCustomBrick = (payload) => (
  backendApiClient.post(ENDPOINTS.VALIDATE_CUSTOM_CODE, payload)
)
export const installPackagesCustomBrick = (payload) => (
  backendApiClient.post(ENDPOINTS.CUSTOM_BRICK_INSTALL_PACKAGES, payload)
)
export const deletePackageCustomBrick = (payload) => (
  backendApiClient.post(ENDPOINTS.CUSTOM_BRICK_DELETE_PACKAGE, payload)
)
export const outputPreviewLoad = (payload) => (
  backendApiClient.post(ENDPOINTS.OUTPUT_PREVIEW_LOAD, payload, {
    timeout: OUTPUT_PREVIEW_LOAD_TIMEOUT_MSEC,
  })
)
export const outputPreviewPage = (payload) => (
  backendApiClient.post(ENDPOINTS.OUTPUT_PREVIEW_PAGE, payload)
)
export const outputPreviewPageCell = (payload) => (
  backendApiClient.post(ENDPOINTS.OUTPUT_PREVIEW_PAGE_CELL, payload)
)
export const transformDataset = (payload) => (
  backendApiClient.post(ENDPOINTS.TRANSFORM_DATASET, payload)
)

export async function waitForTaskStatus({ taskId, apiKeyUuid }) {
  let counter = 0
  let taskStatusResponse = null
  const { delay } = useTime()
  /* eslint-disable no-await-in-loop */
  let isTaskEmpty = true
  while (isTaskEmpty) {
    counter += 1
    await delay(1000)
    try {
      taskStatusResponse = await getTaskStatus({ taskId, apiKeyUuid })
    } catch (e) {
      taskStatusResponse = { data: { isEmpty: true } }
    }
    const { data: { isEmpty } } = taskStatusResponse
    if (counter > 120) {
      break
    }
    if (isEmpty) {
      continue
    } else {
      isTaskEmpty = false
      break
    }
  }
  return taskStatusResponse
}

// Links
const createUrl = (route, { token }) => {
  const url = new URL('/api/v1' + route, API_BASE_URL)
  url.searchParams.append('token', token)
  return url
}

export const linkToDownloadMetafile = (args) => {
  const url = createUrl(ENDPOINTS.GET_PIPELINE_METAFILE(args), args)
  return url.href
}
