<template>
  <div
    v-if="message.textSummary && message.textSummary.length > 1"
    class="message-answer"
  >
    <v-md-editor v-if="message.textSummary" v-model="textSummary" mode="preview" height="auto" class="md-editor"></v-md-editor>
  </div>
</template>

<script setup>
import { computed, defineProps } from 'vue';

const props = defineProps({
  message: {
    type: Object,
    required: true
  }
});
const textSummary = computed({
  get: () => props.message.textSummary,
})
</script>

<style scope>
.message-answer {
  .github-markdown-body {
    padding: 0;
    margin: 0;
    font-size: 16px;
  }

  p:last-child {
    margin-bottom: 0px;
  }
}
</style>