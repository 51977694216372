export function useSanitize() {
  function sanitizeFromXSS(dirtyString) {
    let sanitized = dirtyString.replaceAll('<', '')
    sanitized = sanitized.replaceAll('>', '')
    sanitized = sanitized.replaceAll('"', '')
    sanitized = sanitized.replaceAll('=', '')
    sanitized = sanitized.replaceAll('&', '')
    return sanitized
  }

  function sanitize(dirty) {
    if (typeof dirty === 'string') {
      const sanitized = sanitizeFromXSS(dirty)
      return sanitized
    }
    return dirty
  }

  return {
    sanitize
  }
}