<template>
  <div
    class="o-menu-item"
    :class="itemClass"
    :style="itemStyle"
    @click="handleClick"
  >
    <span
      class="menu-item__icon"
      :class="iconClass"
    >
      <AIcon
        v-if="item.icon"
        :is-original="true"
        :name="`${item.icon}`"
        :height="`${item.iconHeight}`"
        :width="`${item.iconHeight}`"
      />
      <AIcon
        v-if="item.children"
        :is-original="true"
        :direction="arrowDirection"
        width="5"
        height="10"
        name="icons/arrow-left"
      />
    </span>
    <div v-html="item.title || item" />
  </div>
</template>

<script>
import { computed } from 'vue'

import AIcon from '@/shared/components/a-icon'

import { DIRECTION } from '@/shared/constants/placement-direction-constants'
import { EVENT_CLICKED } from '@/shared/constants/bus-events-constants'

export default {
  name: 'OMenuSubItem',
  components: {
    AIcon,
  },
  props: {
    subItemValue: {
      type: String,
      default: '',
    },
    item: {
      type: [Object, String],
      required: true,
    },
  },
  setup(props, { emit }) {
    const arrowDirection = props.item.isIconLeft ? DIRECTION.UP : DIRECTION.DOWN
    const itemStyle = {
      height: props.item.height ? props.item.height : '28px',
      paddingLeft: (props.item.icon && props.item.isIconLeft) || props.item.children ? '26px' : '15px',
      paddingRight: (props.item.icon && props.item.isIconRight) || props.item.children ? '26px' : '15px',
    }
    const iconClass = computed(() => ({
      'menu-item__icon--left': props.item.isIconLeft,
    }))
    const itemClass = computed(() => ({
      'menu-item--selected': (props.item?.value || props.item) === props.subItemValue,
    }))

    function handleClick() {
      emit(EVENT_CLICKED, (props.item?.value || props.item))
    }
    return {
      itemClass,
      iconClass,
      DIRECTION,
      itemStyle,
      arrowDirection,
      handleClick,
    }
  },
}
</script>

<style lang="scss">
@import "o-menu-item";
</style>
