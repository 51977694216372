import { useUrl } from "./use-url"
import { linkToDownloadFile } from "../api/download.api"

export const useDownloadFile = () => {
  const { openUrlInNewTab } = useUrl()

  const downloadFile = ({ path, apiKeyUuid, token }) => {
    const link = linkToDownloadFile({ path, token: token || '', apiKeyUuid })
    openUrlInNewTab(link)
  }

  return {
    downloadFile,
  }
}
