export const menuItems = [
  // {
  //   icon: 'icons/eye',
  //   event: 'preview',
  // },
  // {
  //   icon: 'icons/copy',
  //   event: 'copy',
  // },
]
