<template>
  <img v-if="icon" :src="icon" alt="icon" crossorigin="anonymous" width="32" height="32" />
  <div v-else>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.1525 0.470493C15.3109 -0.156831 16.6889 -0.156831 17.8474 0.470493L28.5 6.23922C29.831 6.96002 30.6666 8.39558 30.6666 9.96169V22.0383C30.6666 23.6044 29.831 25.04 28.5 25.7608L17.8474 31.5295C16.6889 32.1568 15.3109 32.1568 14.1525 31.5295L3.49983 25.7608C2.16879 25.04 1.33325 23.6044 1.33325 22.0383V9.96169C1.33325 8.39558 2.16879 6.96001 3.49983 6.23922L14.1525 0.470493Z" fill="#667479"/>
    </svg>
  </div>
</template>

<script setup>
import { defineProps, ref, watch } from 'vue'
import { useRoute } from 'vue-router'

import { API_BASE_URL } from '@/shared/api/backend-api-client'

const props = defineProps({
  iconPath: {
    type: String,
    default: '',
  },
})

const route = useRoute()
const apiKeyUuid = route.query.apiKeyUuid

const icon = ref(null)

watch(() => props.iconPath, () => {
  fetch(`${API_BASE_URL}/download?path=${props.iconPath}&apiKeyUuid=${apiKeyUuid}`, {
      mode: 'cors'
  })
  .then(response => response.blob())
  .then(imageBlob => {
      const imageObjectURL = URL.createObjectURL(imageBlob);
      icon.value = imageObjectURL;
  });
})
</script>

<style scoped>
.icon {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
</style>
