import store from 'vuex'

export function useCopyToClipboard() {
  // const {
  //   pushErrorSnackbar,
  //   pushDefaultSnackbar,
  // } = store.mapActions

  async function secureCopyToClipboard(text) {
    await navigator.clipboard.writeText(text)
  }

  function unsecuredCopyToClipboard(text) {
    const textArea = document.createElement("textarea")
    textArea.value = text
    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()
    try {
      document.execCommand('copy')
    } catch (err) {
      console.error('Unable to copy to clipboard', err)
    }
    document.body.removeChild(textArea)
  }

  async function copyToClipboard(text) {
    try {
      if (window.isSecureContext && navigator.clipboard) {
        await secureCopyToClipboard(text)
      } else {
        unsecuredCopyToClipboard(text)
      }
      // pushDefaultSnackbar('Copied')
    } catch (err) {
      // pushErrorSnackbar('Failed to copy')
    }
  }

  return {
    copyToClipboard,
  }
}

export default useCopyToClipboard
