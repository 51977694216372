import { ref, computed } from 'vue'

export function useMessageTechDetails({ props }) {
  const isOpened = ref(false)

  const textTechDetails = computed({
    get: () => props.message.textTechDetails,
  })

  function handleOpen() {
    isOpened.value = !isOpened.value
  }

  return {
    isOpened,
    textTechDetails,
    handleOpen,
  }
}