<template>
  <button
    :type="buttonType"
    :disabled="isDisabled"
    :at-a-button="atAttribute"
    :class="[
      configClasses,
      onlyIconClass,
      iconButtonClass
    ]"
    :style="widthStyle"
    class="a-button"
    @click="onClick"
  >
    <ATooltip
      :content="tooltip"
    >
      <slot />
      <div
        v-if="isLoading"
        class="button-loader"
      >
        <ALoader
          :size="15"
          :width="2"
        />
      </div>
      <AIcon
        v-if="iconName"
        :name="iconName"
        :width="iconWidth"
        :height="iconHeight"
        :direction="iconDirection"
        :is-original="isOriginal"
        class="button__icon"
        :class="iconClassOption"
      />
      <span v-if="text">
        {{ text }}
      </span>
    </ATooltip>
  </button>
</template>

<script>
import { computed } from 'vue'

import AIcon from '../a-icon'
import ATooltip from '@/shared/components/a-tooltip'

import ALoader from '@/shared/components/a-loader'

import {
  SMALL,
  SMALL_APP,
  LARGE,
  MEDIUM,
  DEFAULT_BUTTON_WIDTH,
} from '@/shared/constants/size-constants'
import {
  DIRECTION,
  ICON_DIRECTIONS_LIST,
} from '@/shared/constants/placement-direction-constants'
import { BLACK, BUTTON_COLORS } from '@/shared/constants/colors-constants'
import { EVENT_CLICKED } from '@/shared/constants/bus-events-constants'

export default {
  name: 'AButton',
  components: {
    AIcon,
    ALoader,
    ATooltip,
  },
  props: {
    /**
     * Button title text
     */
    text: {
      type: String,
      default: '',
    },
    /**
     * File name (includes path and without extensions)
     *
     * The root directory for this one will be: assets/img or check it in the package.json file
     * example: icons/question-mark
     */
    iconName: {
      type: String,
      default: '',
    },
    /**
     * Rotate icon angle
     */
    iconDirection: {
      type: String,
      default: DIRECTION.UP,
      validator: val => ICON_DIRECTIONS_LIST.includes(val),
    },
    /**
     * Enable/Disable svg original color
     */
    isOriginal: {
      type: Boolean,
      default: false,
    },
    /**
     * Element width
     *
     * by default it will set size points in 'px'
     * example: 20 / 20px / 20rem
     */
    iconWidth: {
      type: [String, Number],
      default: '',
    },
    /**
     * Element height
     *
     * by default it will set size points in 'px'
     * example: 20 / 20px / 20rem
     */
    iconHeight: {
      type: [String, Number],
      default: '',
    },
    /**
     * Button color
     */
    color: {
      type: String,
      default: BLACK,
      validator: val => Object.values(BUTTON_COLORS).includes(val),
    },
    /**
     * Set button type style
     * @values primary, secondary
     */
    theme: {
      type: String,
      default: 'primary',
      validator: value => ['primary', 'secondary', 'transparent'].includes(value),
    },
    /**
     * Set button size style
     * @values small, medium, large
     */
    size: {
      type: String,
      default: SMALL,
      validator: value => [SMALL, SMALL_APP, MEDIUM, LARGE].includes(value),
    },
    /**
     * Min button width
     * example: 70, 100, ...
     */
    minWidth: {
      type: [String, Number],
      default: DEFAULT_BUTTON_WIDTH,
      validator: value => !/\D/.test(value),
    },
    /**
     * Button width
     * example: 70, 100, ...
     */
    width: {
      type: [String, Number],
      default: null,
    },
    /**
     * Set button width 100% of parent block width
     */
    fullWidth: {
      type: Boolean,
      default: false,
    },
    isPressed: {
      type: Boolean,
      default: false,
    },
    /**
     * Set disabled attribute
     */
    isDisabled: {
      type: Boolean,
      default: false,
    },
    /**
     * If tru show loader
     */
    isLoading: {
      type: Boolean,
      default: false,
    },
    iconFillBlack: {
      type: Boolean,
      default: false,
    },
    isDisableMinWidth: {
      type: Boolean,
      default: false,
    },
    /**
     * Button type
     * @values button, submit, reset
     */
    buttonType: {
      type: String,
      default: 'button',
      validator: val => ['submit', 'button', 'reset'].includes(val),
    },
    /**
     * Button tooltip
     */
    tooltip: {
      type: String,
      default: () => null,
    },
    padding: {
      type: [String, Number],
      default: () => null,
    },
    /**
     * locator for automation testing
     */
    atAttribute: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const CLASS_PREFIX = 'button--'

    const configClasses = computed(() => {
      let classes = `${CLASS_PREFIX}${props.size} ${CLASS_PREFIX}${props.theme}-${props.color}`
      if (props.fullWidth) {
        classes += ` ${CLASS_PREFIX}full-width`
      }
      if (props.isPressed) {
        classes += ` button-pressed`
      }
      return classes
    })

    const iconButtonClass = computed(() => {
      return props.iconName ? `${CLASS_PREFIX}icon` : ''
    })

    const onlyIconClass = computed(() => {
      return !props.text ? `${CLASS_PREFIX}only-icon` : ''
    })

    const widthStyle = computed(() => {
      let options = {}
      if (props.width && !props.isDisableMinWidth) {
        options = {
          ...options,
          'width': `${props.width}px`,
        }
      }
      if (props.minWidth && !props.isDisableMinWidth) {
        options = {
          ...options,
          'min-width': `${props.minWidth}px`,
        }
      }
      if (props.padding) {
        options = {
          ...options,
          'padding': `${props.padding}px`,
        }
      }
      return options
    })

    const iconClassOption = computed(() => {
      return {
        'a-icon-fill-black': props.iconFillBlack,
      }
    })

    function onClick(event) {
      /**
       * Emitted when button was click. Payload: native events button
       * @type { MouseEvent }
       */
      emit(EVENT_CLICKED, event)
    }

    return {
      widthStyle,
      configClasses,
      onlyIconClass,
      iconButtonClass,
      iconClassOption,
      onClick,
    }
  },
}
</script>

<style lang="scss">
@import "a-button";
</style>
