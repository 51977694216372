<template>
  <v-dialog v-model="show" persistent max-width="600">
    <v-card>
      <v-card-title class="text-h5">
        Provide additional feedback
      </v-card-title>
      <div class="d-flex flex-column px-3">
        <div class="d-flex align-items-center ga-3 flex-wrap mb-5">
          <div
            v-for="(tab, key) in tags"
            :key="key"
            class="a-modal-feedback__badge"
            :class="{ 'a-modal-feedback__badge--selected': tab.isSelected }"
            @click="onTagClick(tab)"
          >
            {{ tab.name }}
          </div>
        </div>
        <v-textarea
          v-model="customFeedback"
          label="Feel free to add specific details"
          variant="outlined"
          rows="4"
          row-height="4"
        ></v-textarea>
      </div>

      <v-card-actions>
        <v-btn text="Cancel" @click="show = false" :style="{ background: 'black', width: '100px', color: 'white' }"></v-btn>
        <v-btn text="Save" @click="onSubmit" :style="{ background: '#CC3D39', 'border-color': '#CC3D39', width: '100px', color: 'white' }"></v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { computed, defineEmits, defineProps, ref, onMounted } from 'vue'

const emit = defineEmits(['update:modelValue', 'submit'])
const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false
  },
  message: {
    type: Object,
    default: () => ({})
  }
})

const tags = ref([
  {
    name: 'Partial response',
    isSelected: false
  },
  {
    name: `Received an error`,
    isSelected: false
  },
  {
    name: `Didn't fully follow instructions`,
    isSelected: false
  },
  {
    name: `Not factually correct`,
    isSelected: false
  },
  {
    name: `Meaningless visualizations`,
    isSelected: false
  },
  {
    name: `Wrong question interpretation`,
    isSelected: false
  },
])

const customFeedback = ref('')

const show = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value)
})

const onTagClick = (tag) => {
  tag.isSelected = !tag.isSelected
}

const onSubmit = () => {
  const comments = tags.value.filter(tag => tag.isSelected).map(tag => tag.name)
  if (customFeedback.value) {
    comments.push(customFeedback.value)
  }
  emit('submit', { comments, liked: false })
}

onMounted(() => {
  tags.value.forEach(tag => {
    if (props.message.feedback?.comments?.includes(tag.name)) {
      tag.isSelected = true
    }
  })
  const notIntersectedComments = props.message.feedback?.comments?.filter(comment => !tags.value.map(tag => tag.name).includes(comment)) || []
  if (notIntersectedComments?.length) {
    customFeedback.value = notIntersectedComments[0]
  }
})

</script>

<style lang='scss'>
$white: #FFFFFF;
$black: #222528;
$red: #CC3D39;

.a-modal-feedback {
  display: flex;
  justify-content: center;
  align-items: center;

  .a-modal-feedback__button {
    width: 100px;
  }
}
.a-modal-feedback__content {
  display: flex;
  flex-direction: column;
  min-width: 400px;
  background-color: white;
  border-radius: 12px;
  padding: 1.5rem;
}
.a-modal-feedback__title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.a-modal-feedback__badge {
  font-size: 12;
  font-weight: 400;
  line-height: 1.18;

  display: flex;
  justify-content: center;
  align-items: center;
  height: 22px;
  border-radius: 22px;
  padding: 15px;
  background: #F7F8FA;
  border: 1px solid #E0E0E0;
  cursor: pointer;
}

.a-modal-feedback__badge:hover, .a-modal-feedback__badge--selected {
  background: $black;
  color: $white;
}
</style>