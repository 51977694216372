export const DATE_TIME_FORMAT_VALUES = {
  AUTO: 'Auto',
}

export const DATE_TIME_FORMAT_MAP = [
  DATE_TIME_FORMAT_VALUES.AUTO,
  '25-08-94',
  '25-08-1994',
  '08-25-94',
  '08-25-1994',
  '25081994',
  '08251994',
  '1994-08-25',
  '25/08/94',
  '25/08/1994',
  '1994/08/25',
  '8/25/94',
  '8/25/1994',
  '1994/25/8',
  '25 Aug 1994',
  '25 August 1994',
  'Aug 25, 1994',
  'August 25, 1994',
  '8/25/1994 12:34 PM',
  '8/25/94 12:34 PM',
  '25-08-1994 12:34:56',
  '1994-08-25 12:34:56',
  'Thursday, 25 August 1994',
  'Thursday, 25 August 1994, 12:34:56',
  '1667050537000000000',
]

export const TYPES = {
  INTEGER: {
    title: 'Integer',
    value: 'integer',
  },
  FLOAT: {
    title: 'Float',
    value: 'float',
  },
  STRING: {
    title: 'String',
    value: 'string',
  },
  BOOLEAN: {
    title: 'Boolean',
    value: 'boolean',
  },
  CATEGORY: {
    title: 'Category',
    value: 'category',
  },
  DATETIME: {
    title: 'Datetime',
    value: 'datetime',
    children: DATE_TIME_FORMAT_MAP,
  },
}

export const SHORT_TYPES = {
  INTEGER: 'INT',
  FLOAT: 'FLT',
  STRING: 'STR',
  BOOLEAN: 'BLN',
  CATEGORY: 'CAT',
  DATETIME: 'DAT',
  UNDEFINED: 'UND',
}
